<template>
  <div class="wrap">
    <!-- 1페이지 시작 -->
    <section class="cover">
      <div class="cover_img_v2"></div>
      <div class="cover_psn">
        <ul>
          <li><p><span class="num">{{ sample.code }}</span> <span class="name">{{ sample.name }}</span></p></li>
          <li><span>발급처 :</span> <span>에이아이바이오틱스</span></li>
          <li><span>발급일 :</span> <span>{{ moment(new Date()).format('YYYY년 M월 D일') }}</span></li>
          <li><span>문의처 :</span> <span>070<span class="space">-</span>7703<span  class="space">-</span>0380</span></li>
        </ul>
      </div>
      <div class="cover_logo">
        <img src="/reportV2/img/logo_01.png" />
      </div>
    </section>
    <!-- 페이지 끝 -->

    <!-- 2페이지 시작 -->
    <section class="section_cover_02">
    </section>
    <!-- 페이지 끝 -->

    <!-- 3페이지 시작 -->
    <section class="section_cover_v2">
      <div class="cover_psn">
        <ul>
          <li><p><span class="num">{{ sample.code }}</span> <span class="name">{{ sample.name }}</span></p></li>
          <li><span>발급처 :</span> <span>에이아이바이오틱스</span></li>
          <li><span>발급일 :</span> <span>{{ moment(new Date()).format('YYYY년 M월 D일') }}</span></li>
          <li><span>문의처 :</span> <span>070<span class="space">-</span>7703<span  class="space">-</span>0380</span></li>
        </ul>
      </div>
    </section>
    <!-- 페이지 끝 -->

    <!-- 목차 시작 -->
    <section class="section_intro_02" style="height:1580px !important;" >
      <div class="header_01">
        <div class="inner">
          <div class="tit_box">
            <p class="tit_e">TABLE OF CONTENTS</p>
            <p class="tit_k">목차</p>
          </div>
        </div>
      </div>
      <div class="list1">
        <ul>
          <li><span class="bullet"></span>당신의 특성<p>고객정보, 키 성장과 체중의 변화, 에이아이비 차트</p></li>
          <li><span class="bullet"></span>Phylum 분포 평가</li>
          <li><span class="bullet"></span>식성 평가</li>
          <li><span class="bullet"></span>나의 장 유형은</li>
          <li><span class="bullet"></span>나의 유익균<p>Akkermansia, Faecillibacterium, Roseburia, Parabacteroides, Mitsuokella, Odoribacter, Fusicatenibacter, Vampirovibrio, <br>Slackia isoflavoniconvertens</p></li>
          <li><span class="bullet"></span>나의 기회 감염균<p>Citrobacter, Klebsiella, Sutterella, Enterobacter, Proteus, Catabacter, Haemophilus, Duodenibacillus</p></li>
          <li><span class="bullet"></span>나의 유해균<p>Clostridium, Staphylococcus, Salmonella, Shigella, Fusobacteria, Cetobacterium, Lawsonella, Gardnerella</p></li>
          <li><span class="bullet"></span>유산균의 분포<p>Lactobacillus, Bifidobacterium </p></li>
          <li><span class="bullet"></span>당신의 특이균<p>Allisonella Histaminformans, Oxalobacter, Akkermansia, Odoribacter, <br />Bacteroides Plebeius</p></li>
          <li><span class="bullet"></span>당신의 타입과 식이 처방</li>
        </ul>
      </div>
      <span class="page" style="display: none"><span>02</span></span>
    </section>
    <!-- 메뉴 끝 -->

    <!-- 4페이지 시작 -->
    <section class="section_intro_03">
      <div class="intro_img_01"></div>
      <div class="inner">
        <div class="txt_01">
          ‘모든 병은 장에서 시작된다’ <br><br>
          히포크라테스의 말처럼 장은 면역의 기반이자 병의 근원입니다. <br><br>
          그 이유는  장속에 사는 무수히 많은 세균을 비롯한 미생물들 때문입니다. <br><br>

          장 속에 사는 균들은 유익균/유해균/중간균 들이 생태계를 이루어 균형을 맞추고 있습니다. <br>
          사람마다 조금씩 다른 균형의 중심을 하나의 기준으로 단정할 수 없지만 <br>
          내 몸속에 사는 균을 이해하면 건강의 비결을 더 쉽게 찾을 수 있을지도 모릅니다. <br><br>

          이 서비스는 질병을 진단하는 목적으로 사용하지 않습니다. <br><br>

          이 서비스를 통해<br><br>
          1. 혹시 병을 유발할지도 모르는 유해균이 얼마나 많은 지 확인할 수 있습니다.<br>
          2. 유익균과 유해균의 균형을 알아볼 수 있습니다.<br>
          3. 음식궁합이 맞는 균의 존재를 확인할 수 있습니다.<br>
          4. 나에게 맞는 음식과 문제를  파악할 수 있습니다.<br><br>

          결과를 활용하는 방법은 다양합니다.<br><br>

          모든 사람에게 공통적으로 적용되는 건강의 비결은 존재하지 않습니다.<br><br>

          다만 나만의 특성을 이해하고 자신만의 건강의 비결을 찾도록 도와드립니다.<br><br>

        </div>
        <div class="footer_01">
          <span class="logo"><img src="/reportV2/img/logo_02.png" /></span>
          <span class="page"><span>02</span></span>
        </div>
      </div>
    </section>
    <!-- 페이지 끝 -->

    <!-- 5페이지 시작 -->
    <section class="section_intro_03">
      <div class="intro_img_02">
        <div class="inner">
          <div class="txt_02">
            생명이 잉태되는 순간부터 장에는 세균들이 인간의<br>
            몸 안에서 생태계를 만들어 갑니다.<br><br>

            생애 초기에 자리 잡은 균들은 아주 오랫동안 마치<br>
            손금과도 같이 당신의 장을 지배하게 될 것입니다.<br><br>

            내 장을 지배하는 균이 무엇인지 알아 보는 건 마치<br>
            혈액형을 알아야 하는 것과 같이 중요할지도 모릅니다.<br><br>

            모든 음식이 모두에게 똑같이 소화되지 않는 이유가 바로<br>
            장 미생물의 분포가 다르기 때문입니다.<br><br>

            이 검사는 마치 당신의 손금을 보는 것 처럼<br>
            당신 장 속에 자리잡은 균의 종류를 확인할 것이며 당신의<br>
            건강을 위한 새로운 가이드를 제시할 것입니다.<br><br>

            무조건 잡곡을 먹으라고 강요하지 않습니다.<br>
            고기 먹지 말라고, 설탕도 절대로 안된다고 겁주지 않고<br>
            다만 당신의 장 상태를 알고  더 잘 맞는 음식을 알려줍니다.
          </div>
        </div>
      </div>
      <span class="page" style="display: none"><span>02</span></span>
    </section>
    <!-- 페이지 끝 -->

    <!-- 페이지 시작 -->
    <Survey top-margin="false"></Survey>
    <span class="page" style="display: none"><span>02</span></span>
    <!-- 페이지 끝 -->

    <!-- 키성장 시작 -->
    <section class="contents">
      <div class="header_02">
        <div class="inner">
          <div class="tit_box">
            <p class="tit_e">HEIGHT AND WEIGHT</p>
            <p class="tit_k">키 성장과 체중의 변화</p>
            <p class="sub">장내 미생물의 역할로 키를 크게 한다는 연구는 없습니다.<br>
              다만 장 미생물의 조화로움이 영양을 더 잘 소화하고 흡수하는데 도움을 주므로 균형 있는 영양 섭취를 돕기 때문에<br>
              장내 미생물이 성장과도 관련이 있다고 얘기할 수 있겠습니다. </p>
          </div>
        </div>
      </div>
      <div class="inner_02">
        <div class="c_wrap">
          <div class="chart_06" style="width: 1000px !important; height: 495px !important;">
            <div class="type_chart3" style="padding-right: 100px">
              <p class="title"><span class="icon"></span>키 성장</p>
              <v-chart style="left:-38px;" :options="heightChartData" :init-options="{ width: 470, height : 403}" theme='shine' />
            </div>
            <div class="type_chart3">
              <p class="title"><span class="icon"></span>체중의 변화</p>
              <v-chart style="left:-38px;" :options="weightChartData" :init-options="{ width: 470, height : 403}" theme='shine' />
            </div>
          </div>
          <div class="txt_box">
            초록색선을 기준으로 중앙에서 얼마나 떨어져 있는가에 따라서<br><br>
            <span class="txt_b">초록색선 :</span> 질병관리청의 개월별 표준 성장 곡선입니다. <br>
            <span class="txt_b">빨강색선 :</span> 상위 95% 이상에 해당하는 선입니다. <br>
            <span class="txt_b">노랑색선 :</span> 하위 5% 이하에 해당하는 선입니다. <br><br>
            단, 초록색선에서 벗어난 경우라 하더라도 반드시 질환이 있다는 것을 의미하지 않습니다.
          </div>
        </div>
        <dl class="comment">
          <dd style="width:100%">
                        <span>
						장 미생물 중에서 성장에 직접적인 영향을 미치는 균이 존재하지 않습니다.<br>
						하지만 장내 미생물의 균형이 깨어진 경우에는 적절한 성장에 영향을 줄 수도 있습니다.<br>
						유해균의 영향으로 영양분의 적절한 분해와 흡수가 방해되어 성장을 저해한다면 유해균을 줄이는 노력이 필요합니다.<br><br>가장 기본적인 노력은 <span class="txt_b">균형있는 식사</span>와 <span class="txt_b">운동</span>입니다.
						</span>
          </dd>
        </dl>
        <div class="footer_02">
          <span class="logo"><img src="/reportV2/img/logo_02.png"></span>
          <span class="page"><span>02</span></span>
        </div>
      </div>
    </section>
    <!-- 키성장 끝 -->

    <!-- AiB 차트 -->
    <section class="contents">
      <div class="header_02">
        <div class="inner">
          <div class="tit_box">
            <p class="tit_e">AiB CHART</p>
            <p class="tit_k">에이아이비 차트</p>
            <p class="sub">장 미생물의 생태학적 그룹별로 유익성과 유해성을 구분하고 있지만 정확한 구분이 되지는 못하며,<br>
              유익균이나 유해균의 비율이 지나치게 치우치지 않는다면 문제는 없습니다.<br>
              기능적인 분류를 통하여 세부적인 분석을 확인하세요.</p>
          </div>
        </div>
      </div>
      <div class="inner_02">
        <div class="c_wrap">
          <div class="chart_v2">
            <p class="title_v2"><span class="icon_v2"></span>임상적 분류</p>
            <div style="height:340px; ">
              <v-chart :options="aibChart1" ref="polar" theme='shine' :initOptions="{width: 1038, height: 340}"/>
            </div>
          </div>
          <div class="chart_v2 pt30">
            <p class="title_v2"><span class="icon_v2"></span>생물학적 분류</p>
            <div style="height:300px;">
              <v-chart :options="aibChart2" ref="polar" theme='shine' :initOptions="{width: 1038, height: 300}"/>
            </div>
          </div>
        </div>
        <dl class="comment">
          <dd style="width:100%">
                        <span>
						AiB chart는 AiBiotics 사의 독창적인 Microbiome 지도입니다.<br>
						수 백여종의 장내 미생물을 보편적으로 많이 존재하는 순서로 나열한 다음 <span class="txt_b">"나"</span>의 수준을 비교하는 그래프입니다.
						점선은 그래프에서 평균수준을 의미하는 것으로 평균선에서 많이  벗어나는 경우 정상으로 보기 어렵습니다. 하지만 비정상으로 판정되더라도 반드시 질병과 연관되지는 않습니다. <br>비정상으로 분류된 균 중에서 <span class="txt_b">"나"</span>에게 특이한 균이 있을 경우에는 다음장(장유형 Phylum)에서 확인할 수 있습니다.
						</span>
          </dd>
        </dl>
        <div class="footer_02">
          <span class="logo"><img src="/reportV2/img/logo_02.png" alt="logo"></span>
          <span class="page"><span>02</span></span>
        </div>
      </div>
    </section>
    <!-- 페이지 끝 -->

    <!-- 7페이지 시작 -->
    <section class="section_intro_02">
      <div class="header_02">
        <div class="inner">
          <div class="tit_box">
            <p class="tit_e">PHYLUM ANALYSIS</p>
            <p class="tit_k">장 유형  Phylum 분포</p>
            <p class="sub">Phylum은 생물학적 분류기준 중 큰 묶음에 해당합니다.<br />한국인의 평균치에 대비하여 당신의 수준을 아래와 같이 보여줍니다.<br />평균치와 많이 벗어나는 경우에는 원인을 파악해야 합니다. </p>
          </div>
        </div>
      </div>
      <div class="inner_02">
        <div class="c_wrap">
          <div class="chart_v2">
            <p class="title_v2">
             <span class="icon_v2"></span>평균치와 비교한 각 그룹의 농도</p>
            <div style="height:340px;" >
                <v-chart :options="aibChart3" theme='shine' :initOptions="aib3chartSize" />
             </div>

            <div class="chart_v2 pt10">
              <p class="title_v2"><span class="icon_v2"></span>각 그룹의 구성 비율</p>
              <div style="height:310px; ">
                <v-row no-gutters>
                  <v-col cols="6">
                    <v-chart id="test" :options="avgPieData"  theme='shine' :init-options="phylumAvgDistributionRatioSize"
                             style="padding-top: -50px !important;"
                    />
                  </v-col>
                  <v-col cols="5">
                    <v-chart :options="userPieData"  theme='shine' :init-options="phylumUserDistributionRatioSize"
                    />
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>
          <dl class="comment" style="margin-top: 20px" @click.self="showEdit" @blur="showEdit">
            <dt class="icon" @click.self="showEdit"></dt>
            <dd @click.self="showEdit">
              <p v-html="comment.phylumHtml" @click="showEdit" style="word-break:break-word;"></p>
              <editor v-if="isView.phylumEdit"
                    ref="phylumEditor"
                    :initialValue="comment.phylumHtml"
                    :options="editorOptions"
                    initialEditType="wysiwyg"

                    class="comment_edit"
                />
            </dd>
          </dl>
          <div class="footer_02">
            <span class="logo"><img src="/reportV2/img/logo_02.png" /></span>
            <span class="page"><span>02</span></span>
          </div>
        </div>
      </div>
    </section>
    <!-- 페이지 끝 -->

    <!-- 8페이지 시작 -->
    <section class="section_intro_04">
      <div class="inner" style="padding:65px 60px 50px 38px;">
        <div class="type_box">
          <div class="group_01">
            <div class="group_bg">
              <ul>
                <li class="group_top">
                  <span class="icon"></span>
                  <span class="name">
                                        <p class="eng">Bacteroidetes</p>
                                        <p class="kor">박테로이데테스</p>
                                    </span>
                </li>
                <li :class="ageDiversityMicrobeNames.phylum.Bacteroidetes"></li>
                <li class="desc">가장 보편적인 장내 미생물입니다. 간혹 유해균이 있지만 대부분은 공생균과 유익균이 균형을 이루고 있습니다. 섭취하는 음식의 종류에 따라  균의 분포가 변화하기 때문에 평소의 식습관을 알 수 있습니다.</li>
              </ul>
            </div>
          </div>
          <div class="group_02">
            <div class="group_bg">
              <ul>
                <li class="group_top">
                  <span class="icon"></span>
                  <span class="name">
                                        <p class="eng">Actinobacteria</p>
                                        <p class="kor">액티노박테리아</p>
                                    </span>
                </li>
                <li :class="ageDiversityMicrobeNames.phylum.Actinobacteria"></li>
                <li class="desc">‘방선균’ 으로 해석하는 이 균은 실처럼 생긴 모양 때문에 이런 이름을 가지게 되었습니다. 대표적인 유산균인 비피더스 균이 포함된 그룹이며 유익균과 유해균이 공존하는 그룹입니다.</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="type_box">
          <div class="group_01">
            <div class="group_bg">
              <ul>
                <li class="group_top">
                  <span class="icon"></span>
                  <span class="name">
                                        <p class="eng">Firmicutes</p>
                                        <p class="kor">퍼미큐티스</p>
                                    </span>
                </li>
                <li :class="ageDiversityMicrobeNames.phylum.Firmicutes"></li>
                <li class="desc">박테로이데테스 다음으로 많이 분포하는 균종입니다. 지방대사균이 많이 포함되어 있고, 유산균 락토바실러스나 피컬리박테리움과 같은 유익균들과 크로스트리디움 같은 유해균이 공존하는 그룹입니다. 아주 다양한 균종이 존재하기 때문에 특이한 농도를 보이는 경우 주의해야 합니다.</li>
              </ul>
            </div>
          </div>
          <div class="group_02">
            <div class="group_bg">
              <ul>
                <li class="group_top">
                  <span class="icon"></span>
                  <span class="name">
                                        <p class="eng">Fusobacteria</p>
                                        <p class="kor">푸조박테리아</p>
                                    </span>
                </li>
                <li :class="ageDiversityMicrobeNames.phylum.Fusobacteria"></li>
                <li class="desc">물고기의 내장에서는 정상균이지만 인체의 장에서는 유해한 경우가 많습니다. 특히 장염이나 대장 용종의 원인이 되는 균이기 때문에 주의해야 합니다. 보통의 경우에 전혀 발견되지 않기 때문에 낮은 농도가 발견되어도 주의해야 합니다.</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="type_box">
          <div class="group_01">
            <div class="group_bg">
              <ul>
                <li class="group_top">
                  <span class="icon"></span>
                  <span class="name">
                                        <p class="eng">Proteobacteria</p>
                                        <p class="kor">프로테오박테리아</p>
                                    </span>
                </li>
                <li :class="ageDiversityMicrobeNames.phylum.Proteobacteria"></li>
                <li class="desc">평균 분포에서 3번째로 흔한 균의 그룹입니다.  이 그룹에는 유해균이 다수 포함되어 있습니다.  보편적으로 공생하는 균도 있지만 병원성균이 많이 포함되어 있어 농도가 높다면 어떤 병원성균이 있는지  확인해 보는게 좋겠습니다.</li>
              </ul>
            </div>
          </div>
          <div class="group_02">
            <div class="group_bg">
              <ul>
                <li class="group_top">
                  <span class="icon"></span>
                  <span class="name">
                                        <p class="eng">Verrucomicrobia</p>
                                        <p class="kor">베루코마이크로비아</p>
                                    </span>
                </li>
                <li :class="ageDiversityMicrobeNames.phylum.Verrucomicrobia"></li>
                <li class="desc">아카만시아라고 하는 유익균이 이 그룹의 대부분을 차지 합니다.  한국인의 대략 60~70% 가 보편적으로 가지는 균이며, 운동균이라 불릴만큼 운동과 관련성이 있는 매우 유익한 균입니다.</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="footer_02">
          <span class="logo"><img src="/reportV2/img/logo_02.png" /></span>
          <span class="page"><span>02</span></span>
        </div>
      </div>
    </section>
    <!-- 페이지 끝 -->

    <!-- 9페이지 시작 -->
    <section class="section_intro_03">
      <div class="header_02">
        <div class="inner">
          <div class="tit_box">
            <p class="tit_e">FOOD PATTERN</p>
            <p class="tit_k">식성 평가</p>
            <p class="sub">장내 미생물을 변화 시키는 가장 중요한 요인은 음식입니다.<br />어쩌다 한번 먹는 건강식이 장 미생물 환경을 바꾸지 못합니다. 장기간의 식이 습관과 생활의 패턴 그리고 환경요소가<br />더해져서 장의 생태계가 만들어지기 때문에 식이 취향을 알아보는 것은 매우 중요합니다.<br />음식을 선택하는 심리적인 요인도 아주 중요합니다. AIB의 식이 취향 평가는 당신의 식탁이 아닌 당신의 취향을 확인합니다.</p>
          </div>
        </div>
      </div>
      <div class="inner_02">
        <div class="c_wrap">
          <div class="chart_05">
            <div class="container-box">
              <div class="box"></div>
              <div class="square-text" >적정수준</div>
              <div class="circle"></div>
              <div class="circle-text" >나의위치</div>
              <div class="food-pattern-text">적정 수준을 벗어나는 경우 식사 습관이 편중되어 있을 수 있습니다</div>
            </div>
            <v-row no-gutters>
              <v-col cols="5">
                <v-chart :options="tastesType1"  theme='shine' :initOptions="tastedChartSize"/>
              </v-col>
              <v-col cols="2"></v-col>
              <v-col cols="5">
                <v-chart :options="tastesType2"  theme='shine' :initOptions="tastedChartSize" />
              </v-col>
            </v-row>
          </div>
          <div class="txt_box">이 그래프는 당신이 어떤 음식에 더 끌리는지 확인하고 있습니다.<br /><br />
            어떤 경우에도 편중된 식이 취향은 장의 균형을 깨뜨릴 수 있습니다.<br />
            가능하면 추천 레벨수준으로 실제 식사를 조절하는게 좋겠습니다.<br /><br />
            다만 특정 상황에 의해 식이가 조절되어야 하는 상황이라면 이 페이지는 참조로만 활용하시기 바랍니다.</div>
        </div>
        <dl class="comment" style="margin-top: 20px" @click.self="showEatsEdit" @blur="showEatsEdit">
          <dt class="icon" @click.self="showEatsEdit"></dt>
          <dd @click.self="showEatsEdit">
            <p v-html="comment.eatsHtml" @click="showEatsEdit" style="word-break:break-word;"></p>
            <editor v-if="isView.eatsEdit"
                    ref="eatsEditor"
                    :initialValue="comment.eatsHtml"
                    :options="editorOptions"
                    initialEditType="wysiwyg"

                    class="comment_food_edit"
            />
          </dd>
        </dl>
        <div class="footer_02">
          <span class="logo"><img src="/reportV2/img/logo_02.png" /></span>
          <span class="page"><span>02</span></span>
        </div>
      </div>
    </section>
    <!-- 페이지 끝 -->

    <!-- 10페이지 시작 -->
    <section class="section_intro_04" v-if="entroType === 1">
      <div class="header_02">
        <div class="inner">
          <div class="tit_box">
            <p class="tit_e">ENTERO TYPE</p>
            <p class="tit_k">나의 장 유형은?</p>
            <p class="sub">장내 미생물 중 유익균의 역할은 유해균의 성장을 억제하고 장벽을 튼튼하게 유지하도록 보호하며 유익한 물질을<br />분비하여 면역력을 높이는 등의 다양한 기능이 있습니다.<br />하지만 아무리 좋은 균이라도 해도 지나치게 과잉일 경우에는 그 원인에 따라 또 다른 경고가 될 수 있습니다.<br />아무리 좋아도 균은 균일 뿐입니다.</p>
          </div>
        </div>
      </div>
      <div class="inner_02">
        <div class="c_wrap">
          <div class="chart_entro">
            <div class="type_chart" >
              <v-chart :options="bindexChartData.user" :init-options="bindexChartSize" style="left:-49px;top:-4px" theme='shine' />
            </div>
            <div class="type_img">
              <p><img src="/reportV2/img/type_01.jpg" /></p>
              <p class="tc"><span class="type_name2">프리보텔라 유형</span><br /><span>섬유질 섭취형</span></p>
            </div>
          </div>
          <div class="txt_box">프리보텔라는 주로 채식, 식이섬유를 반기는 균입니다. 아기의 경우 분유의 올리고당도 이 균의 먹이가 되기 때문에<br />고형식 이전에는 이 균의 농도가 높은 편입니다.<br /><br />
            박테로이데스는 반대로 육식과, 정제 탄수화물을 좋아하는 균입니다. 육식과 분식에 치중된 일반적인  어린이와 편식하는 어른의 경우 이 균의 비율이 높습니다.<br /><br />
            이 둘의 비교는 선악의 대결이 아닌 취향의 차이입니다.<br />
            다만 프리보텔라가 적을 때는 편식의 영향과 이로 인한 변비에 걸릴 확률이 더 높습니다.<br />
            루미노코커스는 지방을 먹고 사는 균이므로 이 균이 많으면 내가 그동안 지방을 얼마나 많이 먹었는지 확인할 수 있습니다.</div>
        </div>
        <div class="footer_02">
          <span class="logo"><img src="/reportV2/img/logo_02.png" /></span>
          <span class="page"><span>02</span></span>
        </div>
      </div>
    </section>
    <!-- 페이지 끝 -->

    <!-- 11페이지 시작 -->
    <section class="section_intro_03" v-else-if="entroType === 2">
      <div class="header_02">
        <div class="inner">
          <div class="tit_box">
            <p class="tit_e">ENTERO TYPE</p>
            <p class="tit_k">나의 장 유형은?</p>
            <p class="sub">장내 미생물 중 유익균의 역할은 유해균의 성장을 억제하고 장벽을 튼튼하게 유지하도록 보호하며 유익한 물질을<br />분비하여 면역력을 높이는 등의 다양한 기능이 있습니다.<br />하지만 아무리 좋은 균이라도 해도 지나치게 과잉일 경우에는 그 원인에 따라 또 다른 경고가 될 수 있습니다.<br />아무리 좋아도 균은 균일 뿐입니다.</p>
          </div>
        </div>
      </div>
      <div class="inner_02">
        <div class="c_wrap">
          <div class="chart_entro">
            <div class="type_chart" >
              <v-chart :options="bindexChartData.user" :init-options="bindexChartSize" style="left:-49px;top:-4px" theme='shine' />
            </div>
            <div class="type_img">
              <p><img src="/reportV2/img/type_02.jpg" /></p>
              <p class="tc"><span class="type_name2">박테로이데스 유형</span><br /><span>육류 선호형</span></p>
            </div>
          </div>
          <div class="txt_box">프리보텔라는 주로 채식, 식이섬유를 반기는 균입니다. 아기의 경우 분유의 올리고당도 이 균의 먹이가 되기 때문에<br />고형식 이전에는 이 균의 농도가 높은 편입니다.<br /><br />
            박테로이데스는 반대로 육식과, 정제 탄수화물을 좋아하는 균입니다. 육식과 분식에 치중된 일반적인  어린이와 편식하는 어른의 경우 이 균의 비율이 높습니다.<br /><br />
            이 둘의 비교는  선악의 대결이 아닌 취향의 차이입니다.<br />
            다만 프리보텔라가 적을 때는 편식의 영향과 이로 인한 변비에 걸릴 확률이 더 높습니다.<br />
            루미노코커스는 지방을 먹고 사는 균이므로 이 균이 많으면 내가 그동안 지방을 얼마나 많이 먹었는지 확인할 수 있습니다.</div>
        </div>
        <div class="footer_02">
          <span class="logo"><img src="/reportV2/img/logo_02.png" /></span>
          <span class="page"><span>02</span></span>
        </div>
      </div>
    </section>
    <!-- 페이지 끝 -->

    <section class="section_intro_03" v-else>
      <div class="header_02">
        <div class="inner">
          <div class="tit_box">
            <p class="tit_e">ENTERO TYPE</p>
            <p class="tit_k">나의 장 유형은?</p>
            <p class="sub">장유형이 선택 되지 못했음 !!! </p>
          </div>
        </div>
      </div>
      <div class="inner_02">
        <div class="c_wrap">
          <div class="chart_02">
            <div class="type_chart" style="background:#f5f5f5;">
              <v-chart :options="bindexChartData.user"  theme='shine' />
            </div>
            <div class="type_img">
              <p><img src="/reportV2/img/type_02.jpg" /></p>
            </div>
          </div>
          <div class="txt_box"> </div>
        </div>
        <div class="footer_02">
          <span class="logo"><img src="/reportV2/img/logo_02.png" /></span>
          <span class="page"><span>02</span></span>
        </div>
      </div>
    </section>
    <!-- 페이지 끝 -->

    <!-- 12페이지 시작 -->
    <section class="section_intro_03">
      <div class="header_02">
        <div class="inner">
          <div class="tit_box">
            <p class="tit_e">BENEFICIAL BACTERIA</p>
            <p class="tit_k">나의 유익균</p>
            <p class="sub">장내 미생물 중 유익균의 역할은 유해균의 성장을 억제하고 장벽을 튼튼하게 유지하도록 보호하며 유익한 물질을<br />분비하여 면역력을 높이는 등의 다양한 기능이 있습니다.<br />하지만 아무리 좋은 균이라도 해도 지나치게 과잉일 경우에는 그 원인에 따라 또 다른 경고가 될 수 있습니다.<br />아무리 좋아도 균은 균일 뿐입니다.</p>
          </div>
        </div>
      </div>
      <div class="inner_02">
        <div class="c_wrap_02">
          <div class="m_condition">
            <ul>
              <li>
                <div class="name2">
                  <p class="eng">Akkermansia</p>
                  <p class="kor">아카만시아</p>
                </div>
                <div :class="ageDiversityMicrobeNames.beneficialBacteria.Akkermansia"></div>
              </li>
              <li>
                <div class="name2">
                  <p class="eng">Faecillibacterium</p>
                  <p class="kor">페실리박테리움</p>
                </div>
                <div :class="ageDiversityMicrobeNames.beneficialBacteria.Faecalibacterium"></div>
              </li>
              <li>
                <div class="name2">
                  <p class="eng">Roseburia</p>
                  <p class="kor">로즈부리아</p>
                </div>
                <div :class="ageDiversityMicrobeNames.beneficialBacteria.Roseburia"></div>
              </li>
              <li>
                <div class="name2">
                  <p class="eng">Parabacteroides</p>
                  <p class="kor">파라박테로이데스</p>
                </div>
                <div :class="ageDiversityMicrobeNames.beneficialBacteria.Parabacteroides"></div>
              </li>
              <li>
                <div class="name2">
                  <p class="eng">Mitsuokella</p>
                  <p class="kor">미츠오켈라</p>
                </div>
                <div :class="ageDiversityMicrobeNames.beneficialBacteria.Mitsuokella"></div>
              </li>
              <li>
                <div class="name2">
                  <p class="eng">Odoribacter</p>
                  <p class="kor">오도리박터</p>
                </div>
                <div :class="ageDiversityMicrobeNames.beneficialBacteria.Odoribacter"></div>
              </li>
              <li>
                <div class="name2">
                  <p class="eng">Fusicatenibacter</p>
                  <p class="kor">후시카테니박터</p>
                </div>
                <div :class="ageDiversityMicrobeNames.beneficialBacteria.Fusicatenibacter"></div>
              </li>
              <li>
                <div class="name2">
                  <p class="eng">Vampirovibrio</p>
                  <p class="kor">뱀피로비브리오</p>
                </div>
                <div :class="ageDiversityMicrobeNames.beneficialBacteria.Vampirovibrio"></div>
              </li>
              <li>
                <div class="name2">
                  <p class="eng">Slackia isoflavo niconvertens</p>
                  <p class="kor">슬락키아 이소플라보<br />니콘베르텐스</p>
                </div>
                <div :class="ageDiversityMicrobeNames.genusSpecies.isoflavoniconvertens"></div>
              </li>
            </ul>
          </div>
          <div class="m_location">
            <div :class="pin.beneficialBacteria"></div>
            <div class="ml_02">
              <div>
                <ul>
                  <li>
                    <span class="icon a1"></span>
                    <span class="txt">매우낮음</span>
                  </li>
                  <li>
                    <span class="icon a2"></span>
                    <span class="txt">낮음</span>
                  </li>
                  <li>
                    <span class="icon a3"></span>
                    <span class="txt">보통</span>
                  </li>
                  <li>
                    <span class="icon a4"></span>
                    <span class="txt">높음</span>
                  </li>
                  <li>
                    <span class="icon a6"></span>
                    <span class="txt">매우높음</span>
                  </li>
                </ul>
              </div>
              <div class="comment_03">매우 낮은 단계에서부터 매우 높은 단계를 간략하게<br />설명하여 현재 나의 상태를 이해하기 쉽도록 도와줍니다.</div>
            </div>
          </div>
        </div>
        <dl class="comment_02" style="margin-top: 20px" @click.self="showBeneficialEdit" @blur="showBeneficialEdit">
          <dt class="icon" @click.self="showBeneficialEdit"></dt>
          <dd @click.self="showBeneficialEdit">
            <p v-html="comment.beneficialHtml" @click="showBeneficialEdit" style="word-break:break-word;"></p>
            <editor v-if="isView.beneficial"
                    ref="beneficialEditor"
                    :initialValue="comment.beneficialHtml"
                    :options="editorOptions"
                    initialEditType="wysiwyg"

                    class="comment_beneficial_edit"
            />
          </dd>
        </dl>
        <div class="footer_02">
          <span class="logo"><img src="/reportV2/img/logo_02.png" /></span>
          <span class="page"><span>02</span></span>
        </div>
      </div>
    </section>
    <!-- 페이지 끝 -->

    <!-- 13페이지 시작 -->
    <section class="contents">
      <div class="inner_03">
        <div class="con_lt">
          <div class="mt66">
            <div class="pb66"><img src="/reportV2/img/c_img_1.jpg" /></div>
            <div class="Group">
              <span class="icon"></span>
              <span class="name">
                                <p class="eng">Akkermansia</p>
                                <p class="kor">아카만시아</p>
                            </span>
              <span class="desc">차세대 프로바이오틱스로 주목 받고 있는 유익균입니다. 장내 염증을 예방하고, 당뇨 예방에도 기여하는 균으로<br />알려져 있습니다.<br />와인, 차, 사과, 포도 껍질, 홍합 및 블루베리에서 발견되는 카테킨을 포함하여 자연에는 많은 폴리페놀이 있습니다.</span>
              <span class="url"><a href="https://en.wikipedia.org" target="_blank">https://en.wikipedia.org</a></span>
            </div>
            <div class="Group">
              <span class="icon"></span>
              <span class="name">
                                <p class="eng">Faecillibacterium</p>
                                <p class="kor">페실리박테리움</p>
                            </span>
              <span class="desc">건강한 성인의 5% 이상을 차지하는 흔한 장내 세균 중<br />하나이지만 항염증 특성이 있으며 장내 세균 불균형을<br />개선하는 효과가 있습니다. 크론병, 비만, 우울증, 천식<br />발생시 농도가 낮아지는 경향이 있습니다. 장 장벽<br />기능을 향상시킬 수 있습니다.</span>
              <span class="url"><a href="https://en.wikipedia.org" target="_blank">https://en.wikipedia.org</a></span>
            </div>
            <div class="Group">
              <span class="icon"></span>
              <span class="name">
                                <p class="eng">Roseburia</p>
                                <p class="kor">로즈부리아</p>
                            </span>
              <span class="desc">결장에서 부티레이트를 생성, 대사 산물의 장 염증을<br />예방하고, 에너지 항상성을 유지하는데 도움을 줍니다.<br />이 박테리아는 염증성 장 질환, 제2형 당뇨병, 항 인지질<br />증후군 및 죽상동맥경화증과 같은 다양한 질병에 도움이 되며 채식과 관련성이 큰 편입니다.</span>
              <span class="url"><a href="https://pubmed.ncbi.nlm.nih.gov/34881193/" target="_blank">https://pubmed.ncbi.nlm.nih.gov/34881193/</a></span>
            </div>
          </div>
        </div>
        <div class="con_rt">
          <div class="mt66">
            <div class="Group">
              <span class="icon"></span>
              <span class="name">
                                <p class="eng">Parabacteroides</p>
                                <p class="kor">파라박테로이데스</p>
                            </span>
              <span class="desc">장에 유익한 단쇄지방산 중 아세테이트를 생성하는<br />균으로서 급성 췌장염에 도움을 주며 장 염증을<br />완화시켜 줍니다.<br />우유와 같은 유제품 복용 시 증가되는 현상이 관찰됩니다.</span>
              <span class="url"><a href="https://en.wikipedia.org/wiki/Parabacteroides" target="_blank">https://en.wikipedia.org/wiki/Parabacteroides</a></span>
            </div>
            <div class="Group">
              <span class="icon"></span>
              <span class="name">
                                <p class="eng">Fusicatenibacter</p>
                                <p class="kor">후시카테니박터</p>
                            </span>
              <span class="desc">염증성 장질환의 개선에 도움이 되는 물질을 생성하는<br />균입니다.<br />이 균이 생성하는 물질이 염증을 억제하는 효과가<br />있습니다.</span>
              <span class="url"><a href="https://www.jddw.jp/jddw2014/abstracts-eng/abst/60011.html" target="_blank">https://www.jddw.jp/jddw2014/abstracts-eng/abst/60011.html</a></span>
            </div>
            <div class="Group">
              <span class="icon"></span>
              <span class="name">
                                <p class="eng">Vampirovibrio</p>
                                <p class="kor">뱀피로비브리오</p>
                            </span>
              <span class="desc">해조류에 기생하며 클로렐라의 세포액을 먹는 균인데,<br />인체에서 식중독 균을 선택적으로 죽이는 균으로 알려져 있습니다.<br />대표적인 식중독균인 살모넬라와 시겔라 등이 이 균의<br />먹이가 된다고 합니다.</span>
              <span class="url"><a href="https://en.wikipedia.org" target="_blank">https://en.wikipedia.org</a></span>
            </div>
            <div class="Group">
              <span class="icon"></span>
              <span class="name">
                                <p class="eng">Slackia isoflavo niconvertens</p>
                                <p class="kor">슬락키아 이소플라보 니콘베르텐스</p>
                            </span>
              <span class="desc">콩에 들어있는 이소플라본은 심혈관 질환, 유방암, <br>전립선암, 대장암을 포함한 호르몬 관련 증상을 예방하고 건강에 이롭다고 알려져 있습니다.</span>
              <span class="url"><a href="https://www.mdpi.com/2218-1989/5/1/56" target="_blank">https://www.mdpi.com/2218-1989/5/1/56</a></span>
            </div>
            <div><img src="/reportV2/img/c_img_2.jpg" /></div>
          </div>
        </div>
        <div class="footer_02">
          <span class="logo"><img src="/reportV2/img/logo_02.png" /></span>
          <span class="page"><span>02</span></span>
        </div>
      </div>
    </section>
    <!-- 페이지 끝 -->

    <!-- 14페이지 시작 -->
    <section class="contents">
      <div class="header_02">
        <div class="inner">
          <div class="tit_box">
            <p class="tit_e">OPPORTUNISTIC</p>
            <p class="tit_k">나의 기회 감염균</p>
            <p class="sub">기회감염균은 흔하게 존재하지만 면역이 약해지거나 여러 요인들로 인해 건강의 문제를 야기하기 쉬운<br />잠재적인 유해균입니다.<br />완전히 제거되지 않지만 유익균이 많아지고 장이 튼튼해지면 자연스럽게 감소하는 균들입니다.<br />AIB는 수천건의 임상사례를 근거로 아픈 사람들에게 많이 나타나는 균종을 선택하였습니다.<br />농도가 낮을수록 좋습니다.</p>
          </div>
        </div>
      </div>
      <div class="inner_02">
        <div class="c_wrap_02">
          <div class="m_condition">
            <ul>
              <li>
                <div class="name2">
                  <p class="eng">Citrobacter</p>
                  <p class="kor">시트로박터</p>
                </div>
                <div :class="ageDiversityMicrobeNames.genus.Citrobacter"></div>
              </li>
              <li>
                <div class="name2">
                  <p class="eng">Klebsiella</p>
                  <p class="kor">클렙시엘라</p>
                </div>
                <div :class="ageDiversityMicrobeNames.genus.Klebsiella"></div>
              </li>
              <li>
                <div class="name2">
                  <p class="eng">Sutterella</p>
                  <p class="kor">수테렐라</p>
                </div>
                <div :class="ageDiversityMicrobeNames.genus.Sutterella"></div>
              </li>
              <li>
                <div class="name2">
                  <p class="eng">Enterobacter</p>
                  <p class="kor">엔테로박터</p>
                </div>
                <div :class="ageDiversityMicrobeNames.genus.Enterobacter"></div>
              </li>
              <li>
                <div class="name2">
                  <p class="eng">Proteus</p>
                  <p class="kor">프로테우스</p>
                </div>
                <div :class="ageDiversityMicrobeNames.genus.Proteus"></div>
              </li>
              <li>
                <div class="name2">
                  <p class="eng">Catabacter</p>
                  <p class="kor">카타박터</p>
                </div>
                <div :class="ageDiversityMicrobeNames.genus.Catabacter"></div>
              </li>
              <li>
                <div class="name2">
                  <p class="eng">Haemophlius</p>
                  <p class="kor">해모필러스</p>
                </div>
                <div :class="ageDiversityMicrobeNames.genus.Haemophilus"></div>
              </li>
              <li>
                <div class="name2">
                  <p class="eng">Duodenibacillus</p>
                  <p class="kor">듀오데니바실러스</p>
                </div>
                <div :class="ageDiversityMicrobeNames.genus.Duodenibacillus"></div>
              </li>
            </ul>
          </div>
          <div class="m_location">
            <div :class="pin.opportunistic"></div>
            <div class="ml_02">
              <div>
                <ul>
                  <li>
                    <span class="icon a1"></span>
                    <span class="txt">매우높음</span>
                  </li>
                  <li>
                    <span class="icon a2"></span>
                    <span class="txt">높음</span>
                  </li>
                  <li>
                    <span class="icon a3"></span>
                    <span class="txt">보통</span>
                  </li>
                  <li>
                    <span class="icon a4"></span>
                    <span class="txt">낮음</span>
                  </li>
                  <li>
                    <span class="icon a5"></span>
                    <span class="txt">매우낮음</span>
                  </li>
                </ul>
              </div>
              <div class="comment_03">매우 낮은 단계에서부터 매우 높은 단계를 간략하게<br />설명하여 현재 나의 상태를 이해하기 쉽도록 도와줍니다.</div>
            </div>
          </div>
        </div>
        <dl class="comment_02" style="margin-top: 20px" @click.self="showOpportunisticEdit" @blur="showOpportunisticEdit">
          <dt class="icon" @click.self="showOpportunisticEdit"></dt>
          <dd @click.self="showOpportunisticEdit">
            <p v-html="comment.opportunisticHtml" @click="showOpportunisticEdit" style="word-break:break-word;"></p>
            <editor v-if="isView.opportunistic"
                    ref="opportunisticEditor"
                    :initialValue="comment.opportunisticHtml"
                    :options="editorOptions"
                    initialEditType="wysiwyg"

                    class="comment_beneficial_edit"
            />
          </dd>
        </dl>
        <div class="footer_02">
          <span class="logo"><img src="/reportV2/img/logo_02.png" /></span>
          <span class="page"><span>02</span></span>
        </div>
      </div>
    </section>
    <!-- 페이지 끝 -->

    <!-- 15페이지 시작 -->
    <section class="contents">
      <div class="inner_03">
        <div class="con_lt">
          <div class="mt66">
            <div class="pb66"><img src="/reportV2/img/c_img_3.jpg" /></div>
            <div class="Group">
              <span class="icon"></span>
              <span class="name">
                                <p class="eng">Citrobacter</p>
                                <p class="kor">시트로박터</p>
                            </span>
              <span class="desc">대부분의 한국인에게 존재하는 균이지만 수술부위의 감염 혹은 의료 감염의 원인이 됩니다. 특히 신생아의 경우<br />패혈증의 위험도 있습니다. 성인도 면역력이 낮을 때<br />위험도가 커지는 기회 감염균입니다.</span>
              <span class="url"><a href="https://en.wikipedia.org" target="_blank">https://en.wikipedia.org</a></span>
            </div>
            <div class="pb66"><img src="/reportV2/img/c_img_4.jpg" /></div>
            <div class="Group">
              <span class="icon"></span>
              <span class="name">
                                <p class="eng">Klebsiella</p>
                                <p class="kor">클렙시엘라</p>
                            </span>
              <span class="desc">대부분의 한국인에게 존재하는 균이며 폐렴, 요로감염,<br />패혈증, 수막염을 유발할 수 있습니다. 유아기에 흔하게<br />감염되며 병원내 감염이 자주 일어 납니다. 면역이 취약한<br />유아의 감염은 위험할 수 있습니다.</span>
              <span class="url"><a href="https://en.wikipedia.org" target="_blank">https://en.wikipedia.org</a></span>
            </div>
            <div class="Group pbno">
              <span class="icon"></span>
              <span class="name">
                                <p class="eng">Sutterella</p>
                                <p class="kor">수테렐라</p>
                            </span>
              <span class="desc">흔히 성인에서 확인되는 균으로 유해성이 크지 않지만<br />염증을 촉진하는 것으로 알려진 균입니다. 자폐증,<br />비만과의 관련성이 많은 균으로 알려져 있지만<br />너무 흔해서 주의가 떨어지는 균입니다.</span>
              <span class="url"><a href="https://en.wikipedia.org" target="_blank">https://en.wikipedia.org</a></span>
            </div>
          </div>
        </div>
        <div class="con_rt">
          <div class="mt66">
            <div class="Group">
              <span class="icon"></span>
              <span class="name">
                                <p class="eng">Enterobacter</p>
                                <p class="kor">엔테로박터</p>
                            </span>
              <span class="desc">흔하게 발견되는 균이지만 항생제 내성이 있어 항생제 복용후 유익균이 사라진 자리를 가장 먼저 채우는 균입니다.<br />병원성이 크지 않지만 세력이 커지면 요로, 호흡기 등에<br />염증을 유발하기도 합니다.</span>
              <span class="url"><a href="https://en.wikipedia.org" target="_blank">https://en.wikipedia.org</a></span>
            </div>
            <div class="Group">
              <span class="icon"></span>
              <span class="name">
                                <p class="eng">Proteus</p>
                                <p class="kor">프로테우스</p>
                            </span>
              <span class="desc">요로감염균입니다. 소변을 더 알카리성으로 만들기 때문에 방치되면 신장 결석의 원인이 되기도 합니다<br />입원환자의 경우에는 상처 감염 및 패혈증 , 폐렴을<br />유발하기도 합니다.</span>
              <span class="url"><a href="http://koreascience.or.kr/search.page?keywords=JAKO200315875840337" target="_blank">http://koreascience.or.kr › JAKO200315875840337</a></span>
            </div>
            <div class="Group">
              <span class="icon"></span>
              <span class="name">
                                <p class="eng">Catabacter</p>
                                <p class="kor">카타박터</p>
                            </span>
              <span class="desc">장에서의 위험이 크지 않지만 혈액 감염이 되면 위험한<br />균입니다.<br />이 균이 감염되면 발열 구토, 복부팽만, 변비가 수반됩니다.</span>
              <span class="url"><a href="https://en.wikipedia.org" target="_blank">https://en.wikipedia.org</a></span>
            </div>
            <div class="Group">
              <span class="icon"></span>
              <span class="name">
                                <p class="eng">Haemophilus</p>
                                <p class="kor">해모필러스</p>
                            </span>
              <span class="desc">해모필러스 인플루엔자 균은 원래 독감환자에게서<br />발견되어 독감의 원인으로 오해 받아 이런 이름을 갖게<br />되었습니다. 이 균은 균혈증, 폐렴 및 각종 신생아<br />감염균으로 중이염 및 부비동염의 원인이 됩니다.</span>
              <span class="url"><a href="https://en.wikipedia.org" target="_blank">https://en.wikipedia.org</a></span>
            </div>
            <div class="Group">
              <span class="icon"></span>
              <span class="name">
                                <p class="eng">Duodenibacillus</p>
                                <p class="kor">듀오데니바실러스</p>
                            </span>
              <span class="desc">듀오데니바실러스는 프로테오박테리아에 속하는 균으로 십이지장균입니다. </span>
              <span class="url"><a href="https://en.wikipedia.org" target="_blank">https://en.wikipedia.org</a></span>
            </div>
            <div class="pt10"><img src="/reportV2/img/c_img_5_1.jpg" /></div>
          </div>
        </div>
        <div class="footer_02">
          <span class="logo"><img src="/reportV2/img/logo_02.png" /></span>
          <span class="page"><span>02</span></span>
        </div>
      </div>
    </section>
    <!-- 페이지 끝 -->

    <!-- 16페이지 시작 -->
    <section class="contents" style="height:1580px;" >
      <div class="header_02">
        <div class="inner">
          <div class="tit_box">
            <p class="tit_e">HARMFUL BACTERIA</p>
            <p class="tit_k">나의 유해균</p>
            <p class="sub">기회감염균보다 더 유해성이 큰 균들입니다.<br />장에서 독소를 생성하여 염증이나 다양한 질병을 유발합니다.<br />없는게 좋지만 가급적 낮은 농도로 관리되어야 합니다.<br />유해균의 농도가 높아지는 경우는 매우 주의해야 하며 적극적인 관리가 필요합니다.</p>
          </div>
        </div>
      </div>
      <div class="inner_02">
        <div class="c_wrap_02">
          <div class="m_condition">
            <ul>
              <li>
                <div class="name2">
                  <p class="eng">Clostridium</p>
                  <p class="kor">클로스트리디움</p>
                </div>
                <div :class="ageDiversityMicrobeNames.genus.Clostridium"></div>
              </li>
              <li>
                <div class="name2">
                  <p class="eng">Staphylococcus</p>
                  <p class="kor">스타파일로코쿠스</p>
                </div>
                <div :class="ageDiversityMicrobeNames.genus.Staphylococcus"></div>
              </li>
              <li>
                <div class="name2">
                  <p class="eng">Salmonella</p>
                  <p class="kor">살모넬라</p>
                </div>
                <div :class="ageDiversityMicrobeNames.genus.Salmonella"></div>
              </li>
              <li>
                <div class="name2">
                  <p class="eng">Shigella</p>
                  <p class="kor">시겔라</p>
                </div>
                <div :class="ageDiversityMicrobeNames.genus.Shigella"></div>
              </li>
              <li>
                <div class="name2">
                  <p class="eng">Fusobacteria</p>
                  <p class="kor">푸조박테리아</p>
                </div>
                <div :class="ageDiversityMicrobeNames.phylum.Fusobacteria.smallGauge"></div>
              </li>
              <li>
                <div class="name2">
                  <p class="eng">Cetobacterium</p>
                  <p class="kor">세토박테리움</p>
                </div>
                <div :class="ageDiversityMicrobeNames.genus.Cetobacterium"></div>
              </li>
              <li>
                <div class="name2">
                  <p class="eng">Lawsonella</p>
                  <p class="kor">로소넬라</p>
                </div>
                <div :class="ageDiversityMicrobeNames.genus.Lawsonella"></div>
              </li>
              <li>
                <div class="name2">
                  <p class="eng">Gardnerella</p>
                  <p class="kor">가드네렐라</p>
                </div>
                <div :class="ageDiversityMicrobeNames.genus.Gardnerella"></div>
              </li>
            </ul>
          </div>
          <div class="m_location">
            <div :class="pin.harmfulBacteria"></div>
            <div class="ml_02">
              <div>
                <ul>
                  <li>
                    <span class="icon a1"></span>
                    <span class="txt">매우높음</span>
                  </li>
                  <li>
                    <span class="icon a2"></span>
                    <span class="txt">높음</span>
                  </li>
                  <li>
                    <span class="icon a3"></span>
                    <span class="txt">보통</span>
                  </li>
                  <li>
                    <span class="icon a4"></span>
                    <span class="txt">낮음</span>
                  </li>
                  <li>
                    <span class="icon a5"></span>
                    <span class="txt">매우낮음</span>
                  </li>
                </ul>
              </div>
              <div class="comment_03">매우 낮은 단계에서부터 매우 높은 단계를 간략하게<br />설명하여 현재 나의 상태를 이해하기 쉽도록 도와줍니다.</div>
            </div>
          </div>
        </div>
        <dl class="comment_02" style="margin-top: 20px" @click.self="showHarmfulEdit" @blur="showHarmfulEdit">
          <dt class="icon" @click.self="showHarmfulEdit"></dt>
          <dd @click.self="showHarmfulEdit">
            <p v-html="comment.harmfulHtml" @click="showHarmfulEdit" style="word-break:break-word;"></p>
            <editor v-if="isView.harmful"
                    ref="harmfulEditor"
                    :initialValue="comment.harmfulHtml"
                    :options="editorOptions"
                    initialEditType="wysiwyg"

                    class="comment_beneficial_edit"
            />
          </dd>
        </dl>
        <div class="footer_02">
          <span class="logo"><img src="/reportV2/img/logo_02.png" /></span>
          <span class="page"><span>02</span></span>
        </div>
      </div>
    </section>
    <!-- 페이지 끝 -->

    <!-- 17페이지 시작 -->
    <section class="contents" style="height: 1580px !important;">
      <div class="inner_03">
        <div class="con_lt">
          <div style="margin-top:30px;">
            <div class="Group">
              <span class="icon"></span>
              <span class="name">
                                <p class="eng">Clostridium</p>
                                <p class="kor">크로스트리디움</p>
                            </span>
              <span class="desc">크로스트리디움 디피실, 크로스트리디움 퍼프린젠스, 보톨리쿰 등과 같은 병원성이 강한 유해균이 많이 포함되어 있는 그룹입니다. 이 그룹에는 비 병원성 공생균이 공존하고 있지만 농도가 높은 것은 주의가 필요합니다. 이 농도가 높을때는 병원성균을 확인하는 별도의 진단이 필요할 수 있습니다.</span>
              <span class="url"><a href="https://en.wikipedia.org" target="_blank">https://en.wikipedia.org</a></span>
            </div>
            <div class="pb66"><img src="/reportV2/img/c_img_6.jpg" /></div>
            <div class="Group">
              <span class="icon"></span>
              <span class="name">
                                <p class="eng">Staphylococcus</p>
                                <p class="kor">스타파일로코쿠스</p>
                            </span>
              <span class="desc">Staphyloccus는 대략 30%의 인구에서 공생을 하고 있는데, 그 중에서 s.aureus는  질병의 원인이 됩니다. 균혈증과 감염성심내막염 혹은 피부나 점막에 피부 및 유조직을 감염시켜 아토피염증을 유발하기도 합니다. (CDC)</span>
              <span class="url"><a href="https://en.wikipedia.org" target="_blank">https://en.wikipedia.org</a></span>
            </div>
            <div class="pb66"><img src="/reportV2/img/c_img_7.jpg" /></div>
            <div class="Group pbno">
              <span class="icon"></span>
              <span class="name">
                                <p class="eng">Salmonella</p>
                                <p class="kor">살모넬라</p>
                            </span>
              <span class="desc">대표적인 식중독균입니다.  상한 음식으로 감염되는 경우가 많습니다. 감염되면 설사, 발열, 위경련이 나타나며 계란의 오염에 의한 감염사례가 자주 보고됩니다. (CDC)</span>
              <span class="url"><a href="https://en.wikipedia.org" target="_blank">https://en.wikipedia.org</a></span>
            </div>
          </div>
        </div>
        <div class="con_rt">
          <div class="mt66">
            <div class="pb66"><img src="/reportV2/img/c_img_8.jpg" /></div>
            <div class="Group">
              <span class="icon"></span>
              <span class="name">
                                <p class="eng">Shigella</p>
                                <p class="kor">시겔라</p>
                            </span>
              <span class="desc">이질균입니다. 대변을 통한 감염사례가 많으며, 채소<br />과일을 통한 감염도 가능합니다. 감염되면 설사, 복통, 발열 등의 증상이 발생합니다. 청결관리가 매우 중요합니다.</span>
              <span class="url"><a href="https://en.wikipedia.org" target="_blank">https://en.wikipedia.org</a></span>
            </div>
            <div class="Group">
              <span class="icon"></span>
              <span class="name">
                                <p class="eng">Fusobacteria</p>
                                <p class="kor">푸조박테리아</p>
                            </span>
              <span class="desc">어패류의 일반적인 내장균이지만 인간의 내장에서는<br />유해성이 나타납니다. 대장암의 원인균으로 알려져<br />있습니다. 잦은 알콜섭취와 날생선의 섭취와 관련이<br />있습니다.</span>
              <span class="url"><a href="https://en.wikipedia.org" target="_blank">https://en.wikipedia.org</a></span>
            </div>
            <div class="Group">
              <span class="icon"></span>
              <span class="name">
                                <p class="eng">Cetobacterium</p>
                                <p class="kor">세토박테리움</p>
                            </span>
              <span class="desc">에콰도르의 거대 아마존 물고기(아라피마 기가스) 미생물군의 주요 성분이며 반코마이신 치료를 받고 있는 아이의 대변에서 분리되어 아직 이  항생제로 치료되지 않았다는 보고가 있습니다.</span>
              <span class="url"><a href="https://en.wikipedia.org" target="_blank">https://en.wikipedia.org</a></span>
            </div>
            <div class="Group">
              <span class="icon"></span>
              <span class="name">
                                <p class="eng">Lawsonella</p>
                                <p class="kor">로소넬라</p>
                            </span>
              <span class="desc">Lawonella clevelandensis는 농양 형성과 관련된 균으로서 병원성 박테리아이며 복부, 유방, 척추 농양과 관련이 있습니다.</span>
              <span class="url"><a href="https://casereports.bmj.com/content/14/2/e237350" target="_blank">https://casereports.bmj.com/content/14/2/e237350</a></span>
            </div>
            <div class="Group">
              <span class="icon"></span>
              <span class="name">
                                <p class="eng">Gardnerella</p>
                                <p class="kor">가드네렐라</p>
                            </span>
              <span class="desc">Gardneella Vaginalis는 정상적으로 질에 존재하는 박테리아이지만 과잉일 경우에는 세균성 질염을 일으킬 수 있습니다. 또한 과성장하면 세균성 질세균증으로 이어지며 조산 및 기타 성병감염 위험 증가와 관련이 있습니다.</span>
              <span class="url"><a href="https://doi.org/10.1093/ajcp/aqw155.001" target="_blank">https://doi.org/10.1093/ajcp/aqw155.001</a></span>
            </div>
          <!--<div><img src="/reportV2/img/c_img_9.jpg" /></div>-->
          </div>
        </div>
        <div class="footer_02">
          <span class="logo"><img src="/reportV2/img/logo_02.png" /></span>
          <span class="page"><span>02</span></span>
        </div>
      </div>
    </section>
    <!-- 페이지 끝 -->

    <!-- 18페이지 시작 -->
    <section class="contents" style="height:1582px !important;">
      <div class="header_02">
        <div class="inner">
          <div class="tit_box">
            <p class="tit_e">LACTOBACILLUS</p>
            <p class="tit_k">나의 유산균 분포</p>
            <p class="sub">대표적인 유익균인 이 두 종의 유산균은 시판되고 있는 다양한 건강기능식품에 포함되어 있기 때문에 장에 정착된<br />균을 변에서 검출되는 농도로  확인하기는 어렵습니다.<br />섭취하고 있는 유산균과 여기 나오는 결과를 맞춰 보시기 바랍니다.  현재 유산균이 나한테 맞는 것인지<br />알아볼 수 있습니다.</p>
          </div>
        </div>
      </div>
      <div class="inner_02">
        <div class="chart_03">
          <div class="type_chart2">
            <div class="name3">
              <p class="eng">Lactobacillus</p>
              <p class="kor">락토바실러스</p>
            </div>
            <div :class="ageDiversityMicrobeNames.genus.Lactobacillus"></div>
          </div>
          <div class="type_txt">
            <div class="Group">
              <span class="icon"></span>
              <span class="name">
                                    <p class="eng">Lactobacillus</p>
                                    <p class="kor">락토바실러스</p>
                                </span>
              <span class="desc">프로바이오틱스의 일종으로 장내 세균총을 개선하거나<br />회복시켜주며 충분히 섭취할 때 배변활동을 원활하게 도와줍니다.<br />과민성 대장증후군을 완화하는데 도움을 줍니다.</span>
            </div>
          </div>
        </div>
        <div class="chart_03 mt45">
          <div class="type_chart2">
            <div class="name3">
              <p class="eng">Bifidobacterium</p>
              <p class="kor">비피도박테리움</p>
            </div>
            <div :class="ageDiversityMicrobeNames.genus.Bifidobacterium"></div>
          </div>
          <div class="type_txt">
            <div class="Group">
              <span class="icon"></span>
              <span class="name">
                                    <p class="eng">Bifidobacterium</p>
                                    <p class="kor">비피도박테리움</p>
                                </span>
              <span class="desc">대표적인 균주로 면역반응을<br />조절하는 프로바이오틱스라고<br />합니다. 면역력 향상, 알레르기 완화 등에 효과적이라는<br />연구보고가 있습니다.<br />또한 인슐린 저항성 개선 효능을 가지고 있습니다.</span>
            </div>
          </div>
        </div>
        <div class="footer_01">
          <span class="logo"><img src="/reportV2/img/logo_02.png" /></span>
          <span class="page"><span>02</span></span>
        </div>
      </div>
    </section>
    <!-- 페이지 끝 -->
<!--
    <section class="cover" style="height:1580px;">
    </section>
    -->

    <!-- 20페이지 시작 -->
    <section class="contents" style="height:1590px !important;">
      <div class="bg_01">
        <div class="inner_03">
          <div class="con_lt2">
            <div class="Group" style="margin-top:485px;">
                            <span class="desc2">축산업의 발달로 좋은 육질을 만들어 내기 위한 새롭고<br />다양한 방법이 있습니다.<br />
도축전 6개월간 소에게 곡물을 먹이고 운동을 줄이면<br />지방이 고루 퍼지는 마블링이 생기고 고기의 맛이 아주<br />좋아집니다. 하지만 곡물을 많이 먹은 소는 위궤양이<br />생기고 알레르기도 생긴다고 합니다.<br /><br />
왜냐하면 풀을 먹는 소는 위장의 구조가 다릅니다. 풀을<br />소화하기 위해 진화한 소의 소화기관에 전분이 과잉<br />투입되면, 전분 발효 속도가 반추위의 완충 능력을<br />초과합니다. 산이 축적되고 pH가 떨어지고 심한 경우 위궤양으로 죽을 수도 있습니다.<br />
또한 곡물 공급이 히스타민 생산도 촉진한다는 사실이<br />확인되었습니다.<br />
히스타민은 강력한 염증 물질이며 알레르기를 유발하는<br />독성 물질입니다.</span>
            </div>
          </div>
          <div class="con_rt2">
            <div class="Group">
                            <span class="desc2">과학자들은 소의 위장에서 히스타민을 만드는 균을<br /> 찾아냈습니다. 이 새롭게 발견된 균을 배양해 보니 놀랍게도<br /> 히스타민이 생성된 것을 확인하였습니다.<br />
풀만 먹고 사는 것이 자연의 섭리인 소가 육질 때문에 곡물을<br /> 먹으면서 급격하게 증가된 전분이 소화기의 pH와<br /> microbiome의 구성까지 변화시킨 것입니다.<br />
이 균의 이름은  아리조넬라 히스타민포만스 입니다.<br /><br />
그런데 더 중요한 건 이 균이 소한테만 있는게 아니라<br /> 사람한테도 생기고 이게 많으면 알레르기가 더 쉽게 발생할<br /> 가능성이 있습니다.<br />
이 균이 일으킬 수 있는 알레르기가 심하지 않을 수 있지만 혹시 나에게 이 균이 많은 편이라면 전분 위주의 식사보다는 풀이나 고기가 더 나을지도 모릅니다.</span>
            </div>
          </div>
        </div>
      </div>
      <div class="inner_03">
        <div class="chart_04">
          <div class="name3 pt95">
            <p class="eng">Allisonella Histaminformans</p>
            <p class="kor">아리조넬라 히스타민포만스</p>
          </div>
          <div :class="ageDiversityMicrobeNames.genusSpecies2.histaminiformans"></div>
        </div>
        <div class="footer_02">
          <span class="logo"><img src="/reportV2/img/logo_02.png" /></span>
          <span class="page"><span>02</span></span>
        </div>
      </div>
    </section>
    <!-- 페이지 끝 -->

    <!-- 21페이지 시작 -->
    <section class="contents" style="height:1585px !important;">
      <div class="bg_02">
        <div class="inner_03">
          <div class="con_lt2">
            <div class="Group" style="margin-top:200px;">
                            <span class="desc2">뽀빠이는 시금치 통조림을 먹고 헐크 같은 힘이 솟아나<br />올리브를 괴롭히는 브루터스를 무찌르는 옛날 만화입니다.<br />사실 이 만화는 육식을 위주로 하는 미국에서 시금치<br />통조림을 만화로 홍보하기 위해 만들어 졌습니다.<br />
하지만 시금치와 같은 잎채소 중에서는 옥살산이란 성분이<br />포함되어 있습니다. 옥살산은 시금치, 케일 등 잎채소와<br />견과류, 과일등 식물성 식품에 함유되어 있으며 과잉섭취를<br />하거나 자가면역질환이 있는 사람에게는 치명적일 수 있는<br />식물 독소입니다.<br />
식물들도 동물과 마찬가지로 포식자에게 먹히길 원치 않기<br />때문에 적을 공격하기 위한 일종의 화학무기를 가지고<br />있는데 그 중 하나가 옥살산(Oxalic Acid) 또는<br />옥살산염(Oxalates)라고 불리는 물질입니다.<br /><br />
옥살산은 식물에서 발견되는 유기화합물로 몸 속에<br />들어가면 자석처럼 칼슘, 칼륨, 나트륨, 마그네슘과 같은<br />미네랄을 끌어당겨 결합합니다. 이렇게 결합된 화합물을<br />옥산살염이라고 부릅니다.<br />
옥살산과 칼슘이 결합하면 옥살산칼슘, 나트륨과 결합하면<br />옥살산나트륨이라는 옥살산염이 됩니다. 옥살산과<br />미네랄의 결합은 주로 장에서 일어나지만 신장이나<br />요로에서도 발생할 수 있습니다.<br />
신장결석의 80%는 옥살산칼슘으로 이루어져 있다고<br />하는데 혈액에 옥살산이 많아지면 신장으로 가게 되고<br />수용성 옥살산염은 주로 소변을 통해 배출되므로 신장의<br />역할이 중요해집니다.</span>
            </div>
          </div>
          <div class="con_rt2">
            <div class="Group">
                            <span class="desc2"><span class="em">옥살산이 많은 채소류</span><br />
• <span class="em">채소</span> : 완두콩, 시금치, 케일 (어두운 녹색을 띠는 잎채소들),<br />고구마, 감자 (감자칩 포함), 파슬리, 죽순, 양상추, 비트,<br />오크라, 근대<br />
• <span class="em">과일</span> : 키위, 덜 익은 바나나, 베리류, 오렌지, 스타프루트,<br />살구, 무화과<br />
• <span class="em">견과류</span> : 캐슈넛, 아몬드, 땅콩, 헤이즐넛<br />
• <span class="em">기타</span> : 검은 후추, 홍차, 녹차, 코코아파우더, 초콜렛<br /><br />
옥살로박터(Oxalobacter) 탄소원으로 옥살산염만을<br />사용하는 공생 혐기성 미생물인 옥살로박터포르미제네스 <br/>(Oxalobacterformigenes)가 있습니다. 이 균이<br />존재하는 경우 옥살산염 배설을 줄이는 데 지속적으로<br />효과적이라는 것이 확인되었습니다.<br />
옥살산염 항상성에 기여하는 이들 박테리아의 잠재적인<br />역할은 이 옥살산염 분해하는 기능이 있습니다. 쉽게 말해<br />옥살산을 먹이로 하는 균이란 의미입니다.<br />
또한 비피도박테리움 중 B. Animalis 역시 옥살산을<br />줄이는데 기여한다고 합니다.</span>
            </div>
          </div>
        </div>
      </div>
      <div class="inner_03">
        <div class="chart_04">
          <div class="name3 pt95">
            <p class="eng">Oxalobacter</p>
            <p class="kor">옥살로박터</p>
          </div>
          <div :class="ageDiversityMicrobeNames.genus.Oxalobacter"></div>
        </div>
        <div class="footer_02">
          <span class="logo"><img src="/reportV2/img/logo_02.png" /></span>
          <span class="page"><span>02</span></span>
        </div>
      </div>
    </section>
    <!-- 페이지 끝 -->

    <!-- 22페이지 시작 -->
    <section class="contents">
      <div class="bg_03">
        <div class="inner_03">
          <div class="con_lt2">
            <div class="Group" style="margin-top:574px;">
                            <span class="desc2">판매가 허가된 Probiotics는 락토바실러스와 비피더스<br />2종이 전부입니다.<br />
아카만시아 라고 하는 이 균은 가장 늦게 발견되었지만<br />가장 유익성이 큰 균으로 알려져 있습니다.  항염증 기능이<br />매우 우수합니다.<br />
면역의 증진 뿐 아니라, 당뇨의 예방에도 도움이 되는<br />균이라고 합니다.<br /><br />
여러 문헌에서 아카만시아를 증식시켜 주는 음식은 주로 <br />식물의 폴리페놀이라는 내용이 많습니다. 폴리페놀은<br />식물에 존재하는 방향족 알콜 화합물입니다. 흔히 말하는<br />지중해 식단에 많이 포함된 과일과 베리류에 많이<br />있습니다.</span>
            </div>
          </div>
          <div class="con_rt2">
            <div class="Group">
                            <span class="desc2">폴리페놀의 종류는 식물종에 널리 분포하므로 수천 가지가<br />넘는데 녹차에 든 카테킨(catechin), 포도껍질의 레스베라트롤(resveratrol), 사과, 양파의 퀘르세틴(quercetin)이외에도 안토시아닌(anthocyanin), 프로안토시아닌(proanthocyanin)등이 알려져 있습니다. 과일에 많은 플라보노이드(flavonoid)와 콩에 많은 이소플라본(isoflavone)도 폴리페놀의 일종입니다.
 폴리페놀은 광합성에 의해 생성된 식물의 색소와 쓴맛의 성분이므로, 포도처럼 색이 선명하고 떫은맛이나 쓴맛이 나는 식품에 많습니다.<br /><br />
두번째로 이 균을 증식하는 방법은 운동입니다. 유익균이<br />좋아 하는 환경은 약산성을 띄는 상태입니다. 특히 운동을<br />통해 근육이 만들어 내는 ‘젖산’과 ‘마이토카인’이라고 하는<br />성분은 유익균의 성장에 도움을 주고 또한 특히 마이토카인은<br />근육세포 내 미토콘드리아에 자극을 주면 생산되는 성분으로<br />인슐린 저항성을 낮추는 탁월한 효과가 있다고 합니다.<br /><br />
아주 어렵게 설명하고 있지만 결론은 좋은 음식을 먹고<br />운동하면 장이 건강해 진다는 너무나 단순하고 명확하며<br />뻔한 결론입니다.</span>
            </div>
          </div>
        </div>
      </div>
      <div class="inner_03">
        <div class="chart_04">
          <div class="name3 pt95">
            <p class="eng">Akkermansia</p>
            <p class="kor">아카만시아</p>
          </div>
          <div :class="ageDiversityMicrobeNames.beneficialBacteria.Akkermansia.largeGauge"></div>
        </div>
        <div class="footer_02">
          <span class="logo"><img src="/reportV2/img/logo_02.png" /></span>
          <span class="page"><span>02</span></span>
        </div>
      </div>
    </section>
    <!-- 페이지 끝 -->

    <!-- 23페이지 시작 -->
    <section class="contents">
      <div class="bg_04">
        <div class="inner_03">
          <div class="con_lt2">
            <div class="Group" style="margin-top:244px;">
                            <span class="desc2">한국을 비롯한 동양의 대표적인 건강식품 중 교집합을 갖는<br />식품이 콩을 발효한 식품입니다. 일본의 낫토와 미소된장<br />한국의 청국장과 된장은 모두 콩을 발효하여 만든 건강<br />식품입니다. 중국의 춘장은 밀가루와 콩을 발효시킨<br />음식이며, 콩보단 밀가루가 많아서 콩요리라고 하기엔 다소 부족함이 있습니다.<br />
낫토를 만드는 낫토균은 1900년대 초 일본의 학자가<br />발견하였습니다. 낫토균을 삶은 대두에 접종하면 실처럼<br />길게 늘어지는 점액질에 들어 있는 나토키나제를 생성하고<br />이는 혈전 발생을 예방하는 기능이 있습니다. <br />낫토는 나토키나제 이외에도 비타민 B군과 K군, 그리고 다량의 항산화 효소를 함유하고 있습니다.<br />
청국장과 된장은 고초균(바실러스 서브틸러스)이 콩을<br />발효시키는 방법으로 만듭니다. 이 고초균은 자연계에<br />존재하며 주로 짚풀에서 기인하고 있다고 합니다. 메주를<br />짚에다 메단 이유가 여기에 있습니다. 플라스틱으로 만든<br />줄에다 메달아도 발효가 되는 건 공기 중에도 이 균이<br />존재하며 이외에도 여러 종류의 균이 복합적으로 작용한다는 의미입니다.<br /><br />
한국 된장의 가장 큰 차별성은 일본처럼 특정한 균종을<br />따로 주입하는 정형화된 방식이 아닌 자연계에 존재하는<br />바실러스 서브틸러스 균종이 자연스럽게 유입되고, 기타<br />곰팡이 등의 다양한 미생물들이 복합적인 작용으로 발효가<br />되는 특성이 있습니다.</span>
            </div>
          </div>
          <div class="con_rt2">
            <div class="Group">
                            <span class="desc2">발효된 콩을 섭취하면 단백질의 영양분을 힘들이지 않고<br />손쉽게 우리 몸의 자양분으로 만들 수 있습니다. 여기에다<br />뇌졸중과 심혈관질환 예방에도 도움을 준다고 하니 한 마디로<br />‘꿩 먹고 알 먹는’ 식품이라고 할 수 있습니다.<br /><br />
다양한 콩 발효 식품의 공통점은 바실러스, 낫토 균이<br />증식되면서 치료약과 같은 역할을 하는 생리활성물질을<br />만들어 내고 이들 생리활성물질은 항암항균, 소화정장,<br />골다공증 예방, 노화비만방지, 뇌경색과 심근경색의 원인인<br />혈전(血栓)용해 등의 기능이 있습니다.<br /><br />
장을 발효시키는 이 균들보다 사실 인간에게 유익한 균은<br />이 음식을 많이 섭취하면 같이 증가하는 오도리박터라는<br />균입니다.<br /><br />
이 균은 췌장소화액과 같은 성분인 Bile Acid를 생성하는<br />균으로서 과학자들은 이 균을  장수균으로 부르기도 합니다.<br />나이가 들면서 감소하는  Bile Acid를 대체할 수 있기 때문<br />입니다.</span>
            </div>
          </div>
        </div>
      </div>
      <div class="inner_03">
        <div class="chart_04">
          <div class="name3 pt95">
            <p class="eng">Odoribacter</p>
            <p class="kor">오도리박터</p>
          </div>
          <div :class="ageDiversityMicrobeNames.genus.Odoribacter"></div>
        </div>
        <div class="footer_02">
          <span class="logo"><img src="/reportV2/img/logo_02.png" /></span>
          <span class="page"><span>02</span></span>
        </div>
      </div>
    </section>
    <!-- 페이지 끝 -->

    <!-- 24페이지 시작 -->
    <section class="contents">
      <div class="bg_05">
        <div class="inner_03">
          <div class="con_lt2">
            <div class="Group" style="margin-top:453px;">
                            <span class="desc2">'박테로이데스 프레비우스' 라는 균의 별칭은 재패니즈스시 박테리아입니다. 이 균이 별칭에 'japanese' 가 붙은 이유는 미국인의 장에는 없는데 일본 사람들에게는 다 있고, 이 균을 잘 조사해보니 해조류를 분해하는 효소를 만들어 내더라는 것입니다.<br />
다시 말해 이 균이 몸에 있으면 해조류가 잘 소화 되는거고,<br />이 균이 없으면 해조류는 영양분이 아닌 그저 섬유질로서<br />배변만을 돕는 것이라는 이야기입니다.<br />
처음에는 그런가 보다 했는데 지난 4년간 축적한 수천 건의<br />데이터를 잘 들여다보니 우리나라 사람의 90% 가 이 균을<br />가지고 있었습니다.<br /><br />
우리나라 사람부터 검사했다면 'Japanese 스시 박테리아' 대신 ' Korean 미역국 박테리아' 가 되었을지도<br />모르겠습니다.</span>
            </div>
          </div>
          <div class="con_rt2">
            <div class="Group">
                            <span class="desc2">이 균은 해조류라고 해도 멸균된 김에는 없습니다. 생미역, 톳,<br />파래 등 우리가 어릴 때부터 먹던 바다 음식 중 생식으로 먹는<br />중에 인체에 유입되어 장에 자리를 잡게 된다고 합니다.<br />그래서 음식의 살균을 중요시 하는 집이나 미국인은 이 균이<br />어릴 때 장에 들어갈 기회를 잡지 못하게 된다는 겁니다.<br />
어릴 때 이 균이 장에 자리 잡지 못하면 나이들어서 해조류를<br />먹어도 잘 정착하지 못합니다.<br /><br />
미국 산모에게 미역국을 먹이는 건 한국 산모에 비해 효과가<br />매우 낮거나 없게 될 확률을 짐작하게 합니다. 우리나라의<br />젊은 산모와 지나치게 멸균된 음식만 고집하는 집에서는 이런<br />균이 없을 가능성이 있습니다. 이런 분들에겐 산후조리<br />음식으로 미역국 대신 스테이크가 나을지도 모릅니다.</span>
            </div>
          </div>
        </div>
      </div>
      <div class="inner_03">
        <div class="chart_04">
          <div class="name3 pt95">
            <p class="eng">Bacteroides plebeius</p>
            <p class="kor">박테로이데스 프레비우스</p>
          </div>
          <div :class="ageDiversityMicrobeNames.genusSpecies3.plebeius"></div>
        </div>
        <div class="footer_02">
          <span class="logo"><img src="/reportV2/img/logo_02.png" /></span>
          <span class="page"><span>02</span></span>
        </div>
      </div>
    </section>
    <!-- 페이지 끝 -->

    <!-- 25페이지 시작 -->
    <section class="contents">
      <div class="header_01" style="height:230px;">
        <div class="inner">
          <div class="tit_box">
            <p class="tit_e">YOUR TYPE AND FOOD RECOMMENDATIONS</p>
            <p class="tit_k2">당신의 타입과 식이 처방</p>
          </div>
        </div>
      </div>
      <div class="inner_02 pt30">
        <div>
          <table class="tbl_st1" cellpadding="0" cellspacing="0">
            <colgroup>
              <col width="5%">
              <col width="10%">
              <col width="85%">
            </colgroup>
            <tbody>
            <tr>
              <th class="bt">1</th>
              <th class="bt type" @click="selectType">FMGS</th>
              <td class="bt br">{{aibhbiDesc.FMGS}}</td>
            </tr>
            <tr @click="selectType">
              <th>2</th>
              <th class="type" @click="selectType">FMGW</th>
              <td class="br">{{aibhbiDesc.FMGW}}</td>
            </tr>
            <tr>
              <th>3</th>
              <th class="type" @click="selectType">FMBS</th>
              <td class="br">{{aibhbiDesc.FMBS}}</td>
            </tr>
            <tr>
              <th>4</th>
              <th class="type" @click="selectType">FMBW</th>
              <td class="br">{{aibhbiDesc.FMBW}}</td>
            </tr>
            <tr>
              <th>5</th>
              <th class="type" @click="selectType">FVGS</th>
              <td class="br">{{aibhbiDesc.FVGS}}</td>
            </tr>
            <tr>
              <th>6</th>
              <th class="type" @click="selectType">FVGW</th>
              <td class="br">{{aibhbiDesc.FVGW}}</td>
            </tr>
            <tr>
              <th>7</th>
              <th class="type" @click="selectType">FVBS</th>
              <td class="br">{{aibhbiDesc.FVBS}}</td>
            </tr>
            <tr>
              <th>8</th>
              <th class="type" @click="selectType">FVBW</th>
              <td class="br">{{aibhbiDesc.FVBW}}</td>
            </tr>
            <tr>
              <th>9</th>
              <th class="type" @click="selectType">SMGS</th>
              <td class="br">{{aibhbiDesc.SMGS}}</td>
            </tr>
            <tr>
              <th>10</th>
              <th class="type" @click="selectType">SMGW</th>
              <td class="br">{{aibhbiDesc.SMGW}}</td>
            </tr>
            <tr>
              <th>11</th>
              <th class="type" @click="selectType">SMBS</th>
              <td class="br">{{aibhbiDesc.SMBS}}</td>
            </tr>
            <tr>
              <th>12</th>
              <th class="type" @click="selectType">SMBW</th>
              <td class="br">{{aibhbiDesc.SMBW}}</td>
            </tr>
            <tr>
              <th>13</th>
              <th class="type" @click="selectType">SVGS</th>
              <td class="br">{{aibhbiDesc.SVGS}}</td>
            </tr>
            <tr>
              <th>14</th>
              <th class="type" @click="selectType">SVGW</th>
              <td class="br">{{aibhbiDesc.SVGW}}</td>
            </tr>
            <tr>
              <th>15</th>
              <th class="type" @click="selectType">SVBS</th>
              <td class="br">{{aibhbiDesc.SVBS}}</td>
            </tr>
            <tr>
              <th>16</th>
              <th class="type" @click="selectType">SVBW</th>
              <td class="br">{{aibhbiDesc.SVBW}}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="footer_02">
          <span class="logo"><img src="/reportV2/img/logo_02.png" /></span>
          <span class="page"><span>02</span></span>
        </div>
      </div>
    </section>
    <!-- 페이지 끝 -->


    <Aibhbi  ref="aibhbi" >
      <dl slot="aibhbiComment" class="comment_01" style="margin-top: 50px" @click.self="showAibhbiEdit" @blur="showAibhbiEdit">
        <dd @click.self="showAibhbiEdit">
          <p v-html="comment.aibhbiHtml" @click="showAibhbiEdit" style="word-break:break-word;"></p>
          <editor v-if="isView.aibhbi"
                  ref="aibhbiEditor"
                  :initialValue="comment.aibhbiHtml"
                  :options="editorOptions"
                  initialEditType="wysiwyg"

                  class="comment_edit"
          />
        </dd>
      </dl>
    </Aibhbi>

    <v-snackbar
        v-model="snackbar.isView"
        :timeout="snackbar.timeout"
    >
      {{ snackbar.messages }}

      <template v-slot:action="{ attrs }">
        <v-btn
            color="blue"
            text
            v-bind="attrs"
            @click="snackbar.isView = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-overlay :value="overlayLoading">
      <v-progress-circular
          indeterminate
          size="65"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

// striped
<script>
import {  prepareBoxplotData  } from 'echarts/extension/dataTool' ;
import '../theme/toastui-editor.css';
import { Editor } from '@toast-ui/vue-editor'
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css'
import colorSyntax from '@toast-ui/editor-plugin-color-syntax'
import { mapActions } from 'vuex'
import moment from 'moment'
import CssConfig from '../theme/vueTableCssBootstrap4.js'
import axios from 'axios'
import Aibhbi from '@/components/dash/Aibhbi'
import Survey from '@/components/dash/survey'
export default {
  components : {
    Aibhbi,
    Survey,
    editor: Editor,
  },
  name: "report",
  data () {
    return {
      overlayLoading : false,
      aibhibType : "선택되지 않았습니다.",
      snackbar: {
        isView : false,
        timeout : 1000,
        messages : ''
      },
      entroType: 0,
      editorOptions: {
        hideModeSwitch: false,
        height: "500px",
        style : { fontSize: '20px'},
/*
        toolbarItems: [
          ['heading', 'bold', 'italic', 'strike'],
          ['hr', 'quote'],
          ['ul', 'ol', 'task', 'indent', 'outdent'],
          ['scrollSync'],
        ],
*/
        plugins: [[colorSyntax]],
        autofocus : true, },
      aib3chartSize : {
        width: 980,
        height: 340
      },
      tastedChartSize : {
        width: 420,
        height: 420
      },
      bindexChartSize : {
        width: 600,
        height: 400
      },
      phylumAvgDistributionRatioSize : {
        width: 500,
        height: 300
      },
      phylumUserDistributionRatioSize : {
        width: 600,
        height: 400
      },
      bIndexStr: '',
      bIndexClass: '',
      moment : moment,
      sample: {},
      css: CssConfig,
      balance: {},
      starChart : {},
      species : {},
      survey : {},
      sleep : {},
      heightChartData : {},
      weightChartData : {},
      tastesList : {},
      aibChart1 : {},
      aibChart2 : {},
      byAgeDiversity : {},
      freqDistributionListChart : {},
      phylum : {},
      reportPhylum : {},
      reportGenus : {},
      enterotypePieChart : {},
      balanceGauge : {
        size : {
          width: 400,
          height : 400
        },
        gut : {}
      },
      phylumScatter: {
      },
      bifiSpeciesChart : {},
      lactoSpeciesChart : {},
      isView: {
        phylumEdit : false,
        eatsEdit : false,
        beneficial : false,
        opportunistic : false,
        harmful : false,
        aibhbi : false
      },
      comment: {
        phylumHtml : "",
        eatsHtml : "",
        beneficialHtml : "",
        opportunisticHtml : "",
        harmfulHtml : "",
        aibhbiHtml : "",
        type : ''
      },
      GRADE : {
        NONE : 0,
        VERY_LOW : 1,
        LOW : 2,
        NORMAL : 3,
        HIGH : 4,
        VERY_HIGH : 5
      },
      pin : {
        beneficialBacteria : { ml_01:true, b0: true },
        opportunistic :{ ml_01:true, a0: true },
        harmfulBacteria:{ ml_01:true, a0: true },
      },
      ageDiversityMicrobeNames : {
        phylum : {
          Bacteroidetes : { gauge_phylum: true, a0 : true },
          Actinobacteria : {gauge_phylum: true, a0 : true },
          Firmicutes : {gauge_phylum: true, a0 : true },
          Fusobacteria : {gauge_phylum: true, a0 : true , smallGauge : { icon: true, a0 : true} },
          Proteobacteria : {gauge_phylum: true, a0 : true },
          Verrucomicrobia : {gauge_phylum: true, a0 : true }
        },
        genusSpecies : {
          isoflavoniconvertens : {icon: true, a0 : true }
        },
        genusSpecies2 : {
          histaminiformans : {gauge: true, a0 : true } //a0 는 왼쪽이 매우 높음, 오른쪽이 매우 낮음
        },
        genusSpecies3 : {
          plebeius : {gauge: true, b0 : true }
        },
        beneficialBacteria : {
          Akkermansia : { icon: true, a0 : true , largeGauge : { gauge: true, b0 : true} },
          Faecalibacterium : { icon: true, a0 : true },
          Roseburia : { icon: true, a0 : true },
          Parabacteroides : { icon: true, a0 : true },
          Mitsuokella : { icon: true, a0 : true },
          Odoribacter : { icon: true, a0 : true },
          Fusicatenibacter : { icon: true, a0 : true },
          Vampirovibrio : { icon: true, a0 : true }
        },

        genus: {
          Citrobacter : { icon: true, a0 : true },
          Klebsiella : { icon: true, a0 : true },
          Sutterella : { icon: true, a0 : true },
          Enterobacter : { icon: true, a0 : true },
          Proteus : { icon: true, a0 : true },
          Catabacter : { icon: true, a0 : true },
          Haemophilus : { icon: true, a0 : true },
          Duodenibacillus : { icon: true, a0 : true },
          // 유해균 (Fusobacteria 는 phylum 레벨에 있음)
          Clostridium : { icon: true, a0 : true },
          Staphylococcus : { icon: true, a0 : true },
          Salmonella : { icon: true, a0 : true },
          Shigella : { icon: true, a0 : true },
          Cetobacterium : { icon: true, a0 : true },
          Lawsonella : { icon: true, a0 : true },
          Gardnerella : { icon: true, a0 : true },
          // 단독
          Lactobacillus: { gauge: true, a0 : true },
          Bifidobacterium: { gauge: true, a0 : true },
          Oxalobacter: { gauge: true, b0 : true },
          Odoribacter: { gauge: true, b0 : true },
        }
      },
      aibhbiDesc : {
        FMGS : `
  소화가 잘 되서 식욕이 넘치는 편입니다. 상당히 활동적인 편이며 스스로 건강하다고 자만하기 때문에 관리가 소홀해질 가능성이 큽니다.
특히 고지혈증과 이와 관련된 성인병에 주의해야 하며, 성인 남자의 경우에는 대장 건강에도 주의해야 하는 타입니다.
        `,
        FMGW : `
     육식위주의 비만이며 소화가 잘되서 식욕이 넘치지만 자주 아프고 잔병치레가 많은 편입니다.
건강관리가 소홀해져 있으며, 운동이 필요한건 알지만 가능하면 먹어서 해결할려고 하는 게으른 스타일인 경우가 많습니다.
        `,
        FMBS : `
육식을 좋아하지만 소화력이 좋지 않습니다.
배앓이가 잦은 편인데 장내 유해균이 많은 경우도 있지만 소화능력에 비해 식탐이 많은 경우가 더 많습니다.
과식에 의한 배탈이 잦은 편이며, 심리적인 요인 역시 상당히 작용하는 편이기 때문에 식사양을 조절하기 위한 노력이 필요합니다. 포만감을 줄 수 있는 저칼로리 음식이 심리적 안정감을 줄 수 있습니다.
        `,
        FMBW : `
  육식을 좋아하지만 소화가 안되서 힘들고, 운동량도 부족한 흔히 말하는 물살체질입니다.
식사나 약으로 해결하기 어려운 경우가 많으며 생활습관부터 바꾸는게 가장 중요합니다.
        `,
        FVGS : `
채식위주로 식사를 조절하는데 살이 빠지지 않아 고민인 경우가 많습니다.
탄수화물에 중독된 경우가 많으며 면이나 빵을 사랑하는 경우가 많습니다.
        `,
        FVGW : `
고기도 안먹는데 살이 안빠져서 고민이 많은 타입입니다.
면류나 빵류를 즐기는 편이며, 알레르기가 있는 경우가 많습니다. 편중된 식습관은 다양한 성인병으로 연결될 수 있습니다.
        `,
        FVBS : `
빵이나 라면 같은 밀가루 음식과 단 음식를 즐기는 타입입니다.
살찌는게 두려워 무가당 탄산음료를 찾지만 사실 다이어트에 도움이 되지 않을 뿐 아니라 장에 유해균증식을 도울 뿐입니다.
        `,
        FVBW : `
빵이나 라면 같은 밀가루 음식과 단 음료를 즐기는 타입입니다. 안좋은 식습관에다가 운동을 싫어하는 경우가 많습니다.
단음식을 좋아하는 경우가 많기 때문에 당분과 더불어 합성 감미료 역시 아주 조심해야 합니다.  `,
        SMGS : `
건강한 타입입니다. 음식과 생활을 절제하는 편일 수도 있지만 타고난 건강체질로 먹어도 찌지 않는 경우도 많습니다.
음식의 균형을 유지하는 것이 중요합니다. 나이가 들수록 육식을 줄이는게 좋습니다.
        `,
        SMGW : `
덜먹어서 날씬하다면 장에는 문제가 없지만 콜레스테롤이나 고지혈증과 같은 질환에 조심해야 하며, 반드시 적절한 운동이 필요한 타입입니다. 반대로 식욕이 없는 경우라면 오히려 적당량의 조미료가 입맛을 돋구는데 도움이 될 수 있습니다.
        `,
        SMBS : `
식이섬유가 부족한 소식에 적응된 경우 변비에 시달리고 있을 가능성이 있습 니다. 혹은 잦은 장트러블로 고생하는 경우도 있습니다.
육식위주의 식단은 배변주기를 길게 만드는 경우와 장내세균의 균형을 잃어 과민한 장이 되는 두 가지의 경우가 유발됩니다.
        `,
        SMBW : `
고기를 좋아하는데 소화는 안되고 자주 아프기까지 합니다. 소화가 안되면 만사가 귀찮고 기운이 없습니다.
육류 섭취를 줄이기보다는 채식 섭취량을 보충해야 해야 하며, 단백질 보충은 콩류나 생선으로 대체하는 방법도 좋겠습니다.
        `,
        SVGS : `
채식을 즐기고 장 건강도 양호하고 불편한데가 없습니다. 운동을 즐기는 경우가 많습니다.
지나친 채식일변도는 자칫 영양 불균형을 초래할지도 모릅니다.
        `,
        SVGW : `
소식으로 단련되어 식탐이 없지만 대사증후군이나 다른 원인이 있을지도 모르니 주기적인 건강 검진과 무엇보다 음식을 잘 먹어야 합니다.
지나친 채식 일변도 식사는 자칫 영향의 불균형을 초래할 수도 있습니다.
        `,
        SVBS : `
장트러블이 잦고 먹어도 먹어도 소화가 잘 안되지만 의외로 잔병이 없는 타입입니다.
근력이 약한 경우가 대부분입니다. 운동은 근육을 키우기도 하지만 입맛을 돌게하는 부가기능도 있습니다.
        `,
        SVBW : `
먹는게 별로 즐겁지 않은 경우가 많습니다. 주로 간단하게 먹을 수 있는 음식을 찾다 보니 배변주기가 길어지고 변비도 잦습니다.
소화력도 좋지 않아 조금만 많이 먹어도 배탈이 나는 경우가 많습니다.
        `
      }
    }
  },
  methods : {
    ...mapActions(['getSmileChartDataFromApi', 'getByAgeDiversityPhylumFromApi', 'speciesAction', 'getSample', 'getPhylumChartData', 'lactoDetail', 'getBalanceChartDataForReport', 'getByAgeDiversityGenus', 'getByAgeDiversitySpeciesV2']),
    selectType(e) {
      this.snackbar.messages = `${e.target.innerText} 타입이 선택 되었습니다.` ;
      this.snackbar.type = e.target.innerText;
      this.comment.type = e.target.innerText;
      this.snackbar.isView = true;
      //this.aibhibType = e.target.innerText;
      this.$refs.aibhbi.changeTypeContents(e.target.innerText, this.aibhbiDesc[e.target.innerText]);
      this.saveComment();
    },
    getEntroType () {
      try {
        let prevotellaValue = 0;
        let bacteroidesValue = 0;
        for (let obj of this.bindexChartData.user.series[0].data) {
          if (obj.name === "Bacteroides") bacteroidesValue = obj.value;
          if (obj.name === "Prevotella") prevotellaValue = obj.value;
        }

        if (prevotellaValue > bacteroidesValue) {
          this.entroType = 1;
        } else if (prevotellaValue < bacteroidesValue) {
          this.entroType= 2;
        }
        console.log("[getEntroType] %s", this.entroType);
      } catch (e) {
        console.log(e);
        alert("나의 장 유형 차트 데이터 오류");
      }
    },
    async drawSmileChartGenusSpecies(parameters) {
      let resp = await this.getSmileChartDataFromApi(parameters);
    },
    gaugeDirection (microbeName) {
      switch (microbeName) {
        case 'Bacteroidetes' :
        case 'Firmicutes' :
        case 'Proteobacteria' :
        case 'Actinobacteria' :
        case 'Fusobacteria' :
        case 'Verrucomicrobia' :
        case 'Lactobacillus' :
        case 'Bifidobacterium':
        case 'Oxalobacter':
        case 'Odoribacter':
        case 'plebeius':
        //case 'histaminiformans':
              return 'b';
        default :
          return 'a';
      }
    },
    /**
     * api 서버를 통해 smileChart 데이터 가져 오기
     * @param parameters
     * @returns {Promise<void>}
     */
    async drawSmileChart(parameters) {
      let resp = await this.getSmileChartDataFromApi(parameters);
      if (resp.data.code === 200) {
         for( let microbeName of Object.keys(resp.data.data) ){
           // ex)  this.ageDiversityMicrobeNames.phylum.Bacteroidetes.a2 = true;
           this.ageDiversityMicrobeNames[parameters.level][microbeName][this.gaugeDirection(microbeName) + '0'] = false; //기본값 해제
           if (microbeName === "histaminiformans") { // Allisonella Histaminformans 는 오른쪽이 매우 낮음, 즉, 적을 수록 좋음
             this.ageDiversityMicrobeNames[parameters.level][microbeName][this.gaugeDirection(microbeName) + this.reverseLevel(resp.data.data[microbeName].level)] = true;
           } else {
             this.ageDiversityMicrobeNames[parameters.level][microbeName][this.gaugeDirection(microbeName) + resp.data.data[microbeName].level] = true;
           }
           this.ageDiversityMicrobeNames[parameters.level][microbeName].processingResultData = resp.data.data[microbeName];

           //console.log("#debug %s ---> %s", microbeName , JSON.stringify(resp.data.data[microbeName]));
           // 예외 사항: Fusobacteria는 유해균 스마일차트에서도 사용됨
           if (microbeName === "Fusobacteria") {
             //console.log("Fusobacteria %s ---> %s", microbeName , JSON.stringify(resp.data.data[microbeName]));
             this.ageDiversityMicrobeNames[parameters.level][microbeName].smallGauge.a0 = false;
             this.ageDiversityMicrobeNames[parameters.level][microbeName].smallGauge['a' + this.reverseLevel(resp.data.data[microbeName].level)] = true;
             //this.ageDiversityMicrobeNames[parameters.level][microbeName].smallGauge['a' + resp.data.data[microbeName].level] = true;
           }

         } // end for
      }
    },
    // level(Grade) 를 반대로 처리
    reverseLevel(level) {

        switch (level) {
          case this.GRADE.VERY_HIGH:
            level = this.GRADE.VERY_LOW;
            break;
          case this.GRADE.HIGH:
            level = this.GRADE.LOW;
            break;
          case this.GRADE.VERY_LOW:
            level = this.GRADE.VERY_HIGH;
            break;
          case this.GRADE.LOW:
            level = this.GRADE.HIGH;
            break;
        }

        return level;
    },
    showEdit() {
      this.isView.phylumEdit = !this.isView.phylumEdit;
      if (this.isView.phylumEdit === false) {
        this.comment.phylumHtml = this.$refs.phylumEditor.invoke('getHTML');
        this.saveComment().then( msg => {
          this.snackbar.messages = msg;
          this.snackbar.isView = true;
        });
      }
    },
    showEatsEdit() {
      this.isView.eatsEdit = !this.isView.eatsEdit;
      if (this.isView.eatsEdit === false) {
        this.comment.eatsHtml =  this.$refs.eatsEditor.invoke('getHTML');
        this.saveComment().then( msg => {
          this.snackbar.messages = msg;
          this.snackbar.isView = true;
        })
      }
    },
    showBeneficialEdit() {
      this.isView.beneficial = !this.isView.beneficial;
      if (this.isView.beneficial === false) {
        this.comment.beneficialHtml =  this.$refs.beneficialEditor.invoke('getHTML');
        this.saveComment().then( msg => {
          this.snackbar.messages = msg;
          this.snackbar.isView = true;
        })
      }
    },
    showOpportunisticEdit() {
      this.isView.opportunistic = !this.isView.opportunistic;
      if (this.isView.opportunistic === false) {
        this.comment.opportunisticHtml =  this.$refs.opportunisticEditor.invoke('getHTML');
        this.saveComment().then( msg => {
          this.snackbar.messages = msg;
          this.snackbar.isView = true;
        })
      }
    },
    showHarmfulEdit() {
      this.isView.harmful = !this.isView.harmful;
      if (this.isView.harmful === false) {
        this.comment.harmfulHtml =  this.$refs.harmfulEditor.invoke('getHTML');
        this.saveComment().then( msg => {
          this.snackbar.messages = msg;
          this.snackbar.isView = true;
        })
      }
    },
    showAibhbiEdit() {
      this.isView.aibhbi = !this.isView.aibhbi;
      if (this.isView.aibhbi === false) {
        this.comment.aibhbiHtml =  this.$refs.aibhbiEditor.invoke('getHTML');
        this.saveComment().then( msg => {
          this.snackbar.messages = msg;
          this.snackbar.isView = true;
        })
      }
    },
    async saveComment() {

      let data = {
        code : this.sample.code,
        comment : JSON.stringify(this.comment)
      }

      try {
        let resp = await axios.post(`/v2/report/comment`, data);
        if (resp.data.code !== 200) {
          return "데이터 처리 실패 code:" + resp.data.code;
        }
      } catch (e) {
        console.log(e);
        return "잔소리 쓴소리 데이터 저장에 실패 하였습니다.";
      }
      return "잔소리 쓴소리 데이터 저장 완료";
    },
    async getReportSample() {
      await this.getSample();
      this.sample = this.$store.state.sample;
    },
    async getComment() {
      try {
        let resp = await axios.get(`/v2/report/comment/${this.sample.code}`);
        if (resp.data.code !== 200) {
          alert("잔소리 쓴소리 데이터 오류 code:" + resp.data.code);
          return;
        }

        let commentObj = JSON.parse(resp.data.data.comment);
        this.comment = commentObj;

        if (this.comment.type !== undefined && this.comment.type) {
          this.$refs.aibhbi.changeTypeContents(this.comment.type, this.aibhbiDesc[this.comment.type]);
        }

      } catch (e) {
        console.log(e.message);

        if (e.message.indexOf("null") < 0) {
          alert("잔소리 쓴소리가  err:" + e);
        }

        return;
      }
    },
    getReportData() {
      this.getBalanceChartDataForReport().then( balance => {
        this.balance = balance;
      })
      //this.balance = this.$store.state.balance;
      this.starChart = this.$store.state.starChart.bgChart;
      this.species = this.$store.state.species;
      this.survey = this.$store.state.survey;
      this.sleep = this.$store.state.sleepChartData;
      this.heightChartData = this.$store.state.heightChartData;
      this.weightChartData = this.$store.state.weightChartData;
      this.tastesList = this.$store.state.tastesList;
      this.aibChart1 = this.$store.state.aibChart1;
      this.aibChart2 = this.$store.state.aibChart2;
      this.byAgeDiversity = this.$store.state.byAgeDiversity;
      this.freqDistributionListChart = this.$store.state.freqDistributionListChart;
      this.phylum = this.$store.state.phylum;
      this.enterotypePieChart = this.$store.state.enterotypePieChart;
    },
    // 특정 영역에서 DIV > Canvas 위치 조정
    canvasPositionAlign(){
      let canvasCount = 0;
      document.querySelectorAll("canvas[data-zr-dom-id^='zr_']").forEach(obj => {
        switch (canvasCount) {
          case 0 :
            obj.style.left = '25px';
            break;
          case 1 :
            obj.style.left = '25px';
            break;
          case 4 :
            obj.style.top = '5px';
            break;
          case 5:
            obj.style.left = '-18px';
            obj.style.top = '-0px';
            break;
          case 6:
            obj.style.left = '-60px';
            obj.style.top = '-52px';
            break;
          case 7:
          case 8:
            //obj.style.left = '-60px';
            obj.style.top = '-50px';
            //obj.style.height = '200px';
            //obj.style.width = '200px';
            break;
          case 9: //entroType
            obj.style.left = '-60px';
            obj.style.top = '-4px';
            //obj.style.height = '300px';
            //obj.style.width = '400px';
            break;
        }
        canvasCount++;
      });
    },
    /**
     * phylum 차트를 제외한 스마일 차트 데이터 처리
     * - Client 에서 처리
     * @param chartData
     */
    drawFreqDistributionChart (type, chartData = {}) {
      let boxplotPromiseList = [];
      let t = this;
      let rawBoxPlotDataList = []; // 박스 플롯 연산을 위한 종별 데이터 리스트
      let orderEnteredMicrobeObjList = [];

      try {
        Object.keys(chartData).reduce( (acc, microbeName) => {
          boxplotPromiseList.push(_dataProcess(type, microbeName));
        }, 0);

        Promise.all(boxplotPromiseList).then(rawDataObjList => {
         // this.boxPlot(values);
          console.log("!!!!!!!boxplot!!!!!");
          console.dir(rawDataObjList)
          _smileChartDataProc(rawDataObjList);
        })

      } catch (e) {
        alert("차트 데이터를 가져 오는데 실패 했습니다.")
        console.log(e)
        this.overlayLoading = false;
      }

      function _smileChartDataProc(rawDataObjList){

        for (let dataObj of rawDataObjList) {
          let dataList = [];
          dataObj.rawDataList.reduce( (acc, obj) => {
            //if(obj.value === 0) return; // 0 값을 포함 하도록 결정 2022-08-03
            dataList.push(obj.value.toFixed(2));
          }, 0);

          orderEnteredMicrobeObjList.push({
            codeValue : _getCodeValue(dataObj.codeDataList),
            microbeName : dataObj.microbeName
          });

          rawBoxPlotDataList.push(dataList);
        }

        let retBoxPlot = prepareBoxplotData(rawBoxPlotDataList);
        console.log("retBoxPlot");
        console.dir(retBoxPlot);
        _drawSmailChart(retBoxPlot.boxData, orderEnteredMicrobeObjList);
        console.dir(chartData);

        console.log(JSON.stringify(orderEnteredMicrobeObjList))
      }

      const CODE_VALUE_IDX = 1; //요청 코드 데이터 배열 순위
      function _getCodeValue(codeDataList = []) {
        try {
          return codeDataList[0][CODE_VALUE_IDX].toFixed(2);
        }catch (e) {
          console.log("[_getCodeValue] 사용자 데이터 가져 오기 실패");
          return 0;
        }
      }

      const GRADE_NONE = 0;
      const GRADE_VERY_LOW = 1;
      const GRADE_LOW = 2;
      const GRADE_NORMAL = 3;
      const GRADE_HIGH = 4;
      const GRADE_VERY_HIGH = 5;
      function _drawSmailChart(retBoxPlot, orderEnteredMicrobeObjList){
        let microBeNameIdx = 0;
        for( let boxData of retBoxPlot) {
          let microbeObj= orderEnteredMicrobeObjList[microBeNameIdx++];
          let microbeName = microbeObj.microbeName;
          const codeValue = Number(microbeObj.codeValue);
          console.log(codeValue);
          let level = GRADE_NONE;
          if (codeValue > 0 && codeValue <= boxData[0]) {
            level = GRADE_VERY_LOW;
          } else if (codeValue >= boxData[0] && codeValue <= boxData[1]) {
            level = GRADE_LOW;
          } else if (codeValue >= boxData[1] && codeValue <= boxData[2]) {
            level = GRADE_NORMAL;
          } else if (codeValue >= boxData[2] && codeValue <= boxData[3]) {
            level = GRADE_HIGH;
          } else if (codeValue > boxData[3]) {
            level = GRADE_VERY_HIGH;
          }

          chartData[microbeName].a0 = false; //기본값 해제
          // ex)  this.ageDiversityMicrobeNames.phylum.Bacteroidetes.a2 = true;
          chartData[microbeName]['a' + level] = true;
          if(microbeName === 'Akkermansia') {  // 예외, 큰 Gauge도 그려야 하기 때문에
            chartData[microbeName].largeGauge.b0 = false; //기본값 해제
            chartData[microbeName].largeGauge['b' + level] = true;
          }
          chartData[microbeName].processingResultData = {
            boxData : boxData,
            codeValue
          }
        }
      }

      /**
       * 각 이름별 데이터 처리
       * frequencyDistributionForMicrobe 로직 복사 하여 사용
       * 이유: boxplot 차트를 만드는 것이 아닌 boxplot 단계의 데이터를 만들어야 하기 때문에
       * 추후 로직 분리 개선이 필요 할 수도 있음.
       * TODO 서버에서 처리 되도록 로직 이동이 필요
       * @param type :String 종별 레벨을 분리 하기 위해서 사용
       * @param microbeName
       * @returns {Promise<unknown>}
       * @private
       */
      function _dataProcess(type = "default", microbeName) {
        return new Promise((resolve, reject) => {
          if(type === "default" || !_checkSpace(microbeName) ) {
            t.getByAgeDiversityGenus(microbeName).then( (ret) => {
              //boxplot Data 처리를 위한
              resolve({
                microbeName : microbeName,
                rawDataList : ret.rawData,
                codeDataList : ret.codeDataList
              });
            }).catch( e =>{
              console.log(e);
              reject(undefined)
            });

          } else {
            t.getByAgeDiversitySpeciesV2(this.chartData[microbeName]).then( (ret) => {
              //boxplot Data 처리를 위한
              resolve({
                microbeName : microbeName,
                rawDataList : ret.rawData,
                codeDataList : ret.codeDataList
              });
            }).catch( e =>{
              console.log(e);
              reject(undefined)
            });
          }

        });
      } // end dataProcess

      function _checkSpace (str) {
        if(str.search(/\s/) != -1) {
          return true;
        } else {
          return false;
        }
      }

    },
    // TODO 평균 핀 차트를 그리기 위한 AVG 연산
    drawSmileChartAvg(microbeNameObjList) {
      let length = microbeNameObjList.length;
      let codeTotal = 0;
      let codeAvg = 0;
      let totalBoxPlot = [0,0,0,0,0];
      for (let microbeNameObj of microbeNameObjList) {
        codeTotal += microbeNameObj.processingResultData.codeValue;
        let boxData = microbeNameObj.processingResultData.boxData;
          for (let i=0; i < boxData.length; i++) {
            totalBoxPlot[i] = totalBoxPlot[i] + boxData[i];
          }
      }
      console.log("[drawSmileChartAvg]");
      codeAvg = codeTotal / length;
      for (let i=0; i < totalBoxPlot.length; i++) {
        totalBoxPlot[i] = totalBoxPlot[i] / length;
      }
      console.dir(totalBoxPlot);
      console.log(codeAvg)
      return {
        codeAvg,
        totalBoxPlot
      }
    },
    beneficialPin () {
      let beneficialBacteriaList = Object.keys(this.ageDiversityMicrobeNames.beneficialBacteria).reduce( (acc, key) => {
        acc.push(this.ageDiversityMicrobeNames.beneficialBacteria[key]);
        return acc;
      }, [])
      beneficialBacteriaList.push(this.ageDiversityMicrobeNames.genusSpecies.isoflavoniconvertens);
      let retChartAvg = this.drawSmileChartAvg(beneficialBacteriaList);
      let level = this.getLevel(retChartAvg.codeAvg, retChartAvg.totalBoxPlot);

      this.pin.beneficialBacteria.b0 = false;
      this.pin.beneficialBacteria['b' + level] = true;
    },
    opportunisticPin () {
      let opportunisticList = [];
      opportunisticList.push(this.ageDiversityMicrobeNames.genus.Citrobacter);
      opportunisticList.push(this.ageDiversityMicrobeNames.genus.Klebsiella);
      opportunisticList.push(this.ageDiversityMicrobeNames.genus.Sutterella);
      opportunisticList.push(this.ageDiversityMicrobeNames.genus.Enterobacter);
      opportunisticList.push(this.ageDiversityMicrobeNames.genus.Proteus);
      opportunisticList.push(this.ageDiversityMicrobeNames.genus.Catabacter);
      opportunisticList.push(this.ageDiversityMicrobeNames.genus.Haemophilus);
      opportunisticList.push(this.ageDiversityMicrobeNames.genus.Duodenibacillus);

      let retChartAvg = this.drawSmileChartAvg(opportunisticList);
      let level = this.getLevel(retChartAvg.codeAvg, retChartAvg.totalBoxPlot);

      level = this.getReverseLevel(level);

      this.pin.opportunistic.a0 = false;
      this.pin.opportunistic['a' + level] = true;
    },
    harmfulBacteriaPin(){
      let bacteriaList = [];
      bacteriaList.push(this.ageDiversityMicrobeNames.genus.Clostridium);
      bacteriaList.push(this.ageDiversityMicrobeNames.genus.Staphylococcus);
      bacteriaList.push(this.ageDiversityMicrobeNames.genus.Salmonella);
      bacteriaList.push(this.ageDiversityMicrobeNames.genus.Shigella);
      bacteriaList.push(this.ageDiversityMicrobeNames.genus.Cetobacterium);
      bacteriaList.push(this.ageDiversityMicrobeNames.genus.Lawsonella);
      bacteriaList.push(this.ageDiversityMicrobeNames.genus.Gardnerella);
      bacteriaList.push(this.ageDiversityMicrobeNames.phylum.Fusobacteria)

      let retChartAvg = this.drawSmileChartAvg(bacteriaList);
      let level = this.getLevel(retChartAvg.codeAvg, retChartAvg.totalBoxPlot);

      level = this.getReverseLevel(level);

      this.pin.harmfulBacteria.a0 = false;
      this.pin.harmfulBacteria['a' + level] = true;
    },
    //기회 감염균, 유해균은 반대로 표시
    getReverseLevel(level) {
      switch (level) {
        case this.GRADE.VERY_HIGH:
          level = this.GRADE.VERY_LOW;
          break;
        case this.GRADE.HIGH:
          level = this.GRADE.LOW;
          break;
        case this.GRADE.VERY_LOW:
          level = this.GRADE.VERY_HIGH;
          break;
        case this.GRADE.LOW:
          level = this.GRADE.HIGH;
          break;
      }

      return level;
    },
    getLevel(codeValue, boxData){
      let level = this.GRADE.NONE;
      if (codeValue > 0 && codeValue <= boxData[0]) {
        level = this.GRADE.VERY_LOW;
      } else if (codeValue >= boxData[0] && codeValue <= boxData[1]) {
        level = this.GRADE.LOW;
      } else if (codeValue >= boxData[1] && codeValue <= boxData[2]) {
        level = this.GRADE.NORMAL;
      } else if (codeValue >= boxData[2] && codeValue <= boxData[3]) {
        level = this.GRADE.HIGH;
      } else if (codeValue > boxData[3]) {
        level = this.GRADE.VERY_HIGH;
      }
      return level;
    }

  },
  async mounted () {
    this.overlayLoading = true;
    console.log('#report mounted')
    await this.drawSmileChart(
        {
          level : 'phylum',
          pLevel : '',
          microbeNameList :  Object.keys(this.ageDiversityMicrobeNames.phylum)
        }
    );
    await this.drawSmileChart(
        {
          level : 'genusSpecies', //pLevel 이 있을 경우에는 microbeNameList 는 species 로 처리 됨
          pLevel : 'Slackia',     //genus name
          microbeNameList :  Object.keys(this.ageDiversityMicrobeNames.genusSpecies)
        }
    );
    this.getReportData();
    //this.balance = this.balanceChart();
    await this.getReportSample();
    await this.getComment();
    await this.getEntroType();
    await this.drawFreqDistributionChart("default", this.ageDiversityMicrobeNames.beneficialBacteria);
    await this.drawSmileChart(
        {
          level : 'genus', //pLevel 이 있을 경우에는 microbeNameList 는 species 로 처리 됨
          pLevel : '',     //genus name
          microbeNameList :  Object.keys(this.ageDiversityMicrobeNames.genus)
        }
    );
    await this.drawSmileChart(
        {
          level : 'genusSpecies2', //pLevel 이 있을 경우에는 microbeNameList 는 species 로 처리 됨
          pLevel : 'Allisonella',     //genus name
          microbeNameList :  Object.keys(this.ageDiversityMicrobeNames.genusSpecies2)
        }
    );
    await this.drawSmileChart(
        {
          level : 'genusSpecies3', //pLevel 이 있을 경우에는 microbeNameList 는 species 로 처리 됨
          pLevel : 'Bacteroides',     //genus name
          microbeNameList :  Object.keys(this.ageDiversityMicrobeNames.genusSpecies3)
        }
    );

    this.beneficialPin();
    this.opportunisticPin();
    this.harmfulBacteriaPin();
    this.overlayLoading = false;
  },
  created () {
  },
  beforeCreate () {
    console.log("!!!!!!!!!!!");
    console.dir(document.body.removeAttr("style"));
  },
  updated () {
    this.$nextTick(function() {
      let divPage = document.querySelectorAll( 'span.page' );
      for ( let i = 0; i < divPage.length; i++ ) {
        divPage[i].innerText = i + 1;
      }
    });

    this.canvasPositionAlign();
  },
  computed: {
    bindexChartData: function () {
        return this.$store.state.enterotypePieChart;
    },
    userPieData : function() {
      return this.$store.state.phylum.userPieData;
    },
    avgPieData : function() {
      return this.$store.state.phylum.avgPieData;
    },
    aibChart3 () {
      return this.$store.state.aibChart3;
    },
    tastesType1() {
      return this.$store.state.tastesList.type1;
    },
    tastesType2() {
      return this.$store.state.tastesList.type2;
    }
  }
}
</script>
<style lang="scss" scoped>
p::v-deep p {
  padding: 0;
  margin-bottom: 0px !important;
  font-siz: 18px;
}
@import '../theme/reportV2.scss';
.container-box{
  width: 100px;
  height: 100px;
  position: absolute;
}
.box{
  width: 28px;
  height: 48px;
  position: absolute;
  top: -20px;
  left: 865px;
  background-color: #9bca3c;
}

.square-text {
  width: 52px;
  height: 50px;
  position: absolute;
  font-size:10px;
  top: 30px;
  left: 860px;
}

.circle-text {
  width: 52px;
  height: 50px;
  position: absolute;
  font-size:10px;
  top: 30px;
  left: 910px;
}

.circle{
  z-index: 9;
  margin-left: 915px;
  width: 25px;
  height: 25px;
  position: absolute;
  top: -10px;
  left: 0;
  -moz-border-radius: 45px;
  -webkit-border-radius: 45px;
  border: 0px solid black;
  background: #ff0000;
}

.food-pattern-text {
  width: 500px;
  height: 50px;
  position: absolute;
  top: 355px;
  left: -20px;
}

</style>
