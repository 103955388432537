<template>
    <!-- 페이지시작 -->
    <section class="contents" >
      <div v-if="topMargin === true" style="height: 50px"></div>
      <table class="surveytb">
        <tbody>
        <tr class="cate">
          <td colspan="8">고객정보답변</td>
        </tr>
        <tr class="survey-title">
          <td>Sample ID</td>
          <td style="width: 15%">작성일</td>
          <td style="width: 11%">이름</td>
          <td style="width: 11%">나이</td>
          <td style="width: 15%">생년월일</td>
          <td style="width: 11%">신장</td>
          <td style="width: 11%">체중</td>
          <td style="width: 11%">BMI</td>
        </tr>
        <tr>
          <td>{{ sample.code }}</td>
          <td>{{ moment(new Date()).format('YYYY.MM.DD') }}</td>
          <td>{{ survey.name }}</td>
          <td>{{ survey.age }}</td>
          <td>{{ survey.birthday_year + '.' + survey.birthday_month + '.' + survey.birthday_day }}</td>
          <td>{{ survey.height }}cm</td>
          <td>{{ survey.weight }}kg</td>
          <td>{{ survey.bmi }}</td>
        </tr>
        </tbody>
      </table>
      <table class="surveytb surveytb_2">
        <tbody>
        <tr class="cate">
          <td colspan="6">현재 함께 거주하는 가족 정보</td>
        </tr>
        <tr class="survey-title">
          <td style="width: 16.6%">조부모</td>
          <td style="width: 16.6%">부모</td>
          <td style="width: 16.6%">형제</td>
          <td style="width: 16.6%">자녀</td>
          <td style="width: 16.6%">애완동물</td>
          <td style="width: 16.6%">총</td>
        </tr>
        <tr>
          <td>{{ survey.parent_num }}</td>
          <td>{{ survey.spouse_num }}</td>
          <td>{{ survey.brother_num }}</td>
          <td>{{ survey.children_num }}</td>
          <td>{{ survey.pet_num }}</td>
          <td>{{ survey.family_num }}</td>
        </tr>
        </tbody>
      </table>
      <table class="surveytb surveytb_2">
        <tbody>
        <colgroup>
          <col width="">
        </colgroup>
        <tr class="cate">
          <td>식사답변 (내가 즐겨먹는 음식)</td>
        </tr>
        <tr>
          <td class="food">{{ survey.like_adultfood }}</td>
        </tr>
        </tbody>
      </table>
      <table class="surveytb surveytb_2">
        <tbody>
        <tr class="cate">
          <td colspan="6">식성</td>
        </tr>
        <tr class="survey-title">
          <td style="width: 16.6%">뭐든 잘먹는 편이다</td>
          <td style="width: 16.6%">달게 먹는편이다</td>
          <td style="width: 16.6%">짜게먹는편이다</td>
          <td style="width: 16.6%">편식하는 경향이 있다</td>
          <td style="width: 16.6%">식탐이 있는편이다</td>
          <td style="width: 16.6%">야식을 자주 먹는다</td>
        </tr>
        <tr>
          <td>{{ survey.taste1 }}</td>
          <td>{{ survey.taste2 }}</td>
          <td>{{ survey.taste3 }}</td>
          <td>{{ survey.taste4 }}</td>
          <td>{{ survey.taste5 }}</td>
          <td>{{ survey.taste6 }}</td>
        </tr>
        </tbody>
      </table>
      <table class="surveytb surveytb_2">
        <tbody>
        <tr class="cate">
          <td colspan="5">선호음식</td>
        </tr>
        <tr class="choice">
          <td style="width: 20%" v-if="survey.likefood1 === '고기'"><em>고기</em> vs 채소 vs 생선</td>
          <td style="width: 20%" v-else-if="survey.likefood1 === '채소'">고기 vs <em>채소</em> vs 생선</td>
          <td style="width: 20%" v-else-if="survey.likefood1 === '생선'">고기 vs 채소 vs <em>생선</em></td>
          <td style="width: 20%" v-if="survey.likefood3 === '국물만'"><em>국물만</em> vs 건더기만 vs 둘다</td>
          <td style="width: 20%" v-else-if="survey.likefood3 === '건더기만'">국물만 vs <em>건더기만</em> vs 둘다</td>
          <td style="width: 20%" v-else-if="survey.likefood3 === '둘다'">국물만 vs 건더기만 vs <em>둘다</em></td>
          <td style="width: 20%" v-if="survey.likefood5 === '기름진 음식'"><em>기름진 음식</em> vs 담백한 음식</td>
          <td style="width: 20%" v-else-if="survey.likefood5 === '담백한 음식'">기름진 음식 vs <em>담백한 음식</em></td>
          <td style="width: 20%" v-if="survey.likefood7 === '김치'"><em>김치</em> vs 소시지</td>
          <td style="width: 20%" v-else-if="survey.likefood7 === '소시지'">김치 vs <em>소시지</em></td>
          <td style="width: 20%" v-if="survey.likefood9 === '파스타'"><em>파스타</em> vs 돈까스</td>
          <td style="width: 20%" v-else-if="survey.likefood9 === '돈까스'">파스타 vs <em>돈까스</em></td>
        </tr>
        <tr class="choice">
          <td v-if="survey.likefood11 === '케이크'"><em>케이크</em> vs 샌드위치</td>
          <td v-else-if="survey.likefood11 === '샌드위치'">케이크 vs <em>샌드위치</em></td>
          <td v-if="survey.likefood13 === '치즈'"><em>치즈</em> vs 햄</td>
          <td v-else-if="survey.likefood13 === '햄'">치즈 vs <em>햄</em></td>
          <td v-if="survey.likefood15 === '양송이스프'"><em>양송이스프</em> vs 치킨스프</td>
          <td v-else-if="survey.likefood15 === '치킨스프'">양송이스프 vs <em>치킨스프</em></td>
          <td v-if="survey.likefood17 === '냉커피'"><em>냉커피</em> vs 이온음료</td>
          <td v-else-if="survey.likefood17 === '이온음료'">냉커피 vs <em>이온음료</em></td>
          <td v-if="survey.likefood19 === '쌀국수'"><em>쌀국수</em> vs 순두부</td>
          <td v-else-if="survey.likefood19 === '순두부'">쌀국수 vs <em>순두부</em></td>
        </tr>
        <tr class="choice">
          <td v-if="survey.likefood2 === '구운요리'"><em>구운요리</em> vs 국물요리</td>
          <td v-else-if="survey.likefood2 === '국물요리'">구운요리 vs <em>국물요리</em></td>
          <td v-if="survey.likefood4 === '떡볶이'"><em>떡볶이</em> vs 과일 vs 햄버거</td>
          <td v-else-if="survey.likefood4 === '과일'">떡볶이 vs <em>과일</em> vs 햄버거</td>
          <td v-else-if="survey.likefood4 === '햄버거'">떡볶이 vs 과일 vs <em>햄버거</em></td>
          <td v-if="survey.likefood6 === '콜라'"><em>콜라</em> vs 주스</td>
          <td v-else-if="survey.likefood6 === '주스'">콜라 vs <em>주스</em></td>
          <td v-if="survey.likefood8 === '감자튀김'"><em>감자튀김</em> vs 사과</td>
          <td v-else-if="survey.likefood8 === '사과'">감자튀김 vs <em>사과</em></td>
          <td v-if="survey.likefood10 === '치킨'"><em>치킨</em> vs 백숙</td>
          <td v-else-if="survey.likefood10 === '백숙'">치킨 vs <em>백숙</em></td>
        </tr>
        <tr class="choice">
          <td v-if="survey.likefood12 === '김밥'"><em>김밥</em> vs 라면</td>
          <td v-else-if="survey.likefood12 === '라면'">김밥 vs <em>라면</em></td>
          <td v-if="survey.likefood14 === '고구마'"><em>고구마</em> vs 바나나</td>
          <td v-else-if="survey.likefood14 === '바나나'">고구마 vs <em>바나나</em></td>
          <td v-if="survey.likefood16 === '주스'"><em>주스</em> vs 요구르트</td>
          <td v-else-if="survey.likefood16 === '요구르트'">주스 vs <em>요구르트</em></td>
          <td v-if="survey.likefood18 === '팬케이크'"><em>팬케이크</em> vs 시리얼</td>
          <td v-else-if="survey.likefood18 === '시리얼'">팬케이크 vs <em>시리얼</em></td>
          <td v-if="survey.likefood20 === '아메리카노'"><em>아메리카노</em> vs 마끼야또</td>
          <td v-else-if="survey.likefood20 === '마끼야또'">아메리카노 vs <em>마끼야또</em></td>
        </tr>
        </tbody>
      </table>
      <table class="surveytb surveytb_2">
        <tbody>
        <tr class="cate">
          <td colspan="3">건강 답변 “수면”</td>
          <td colspan="2">건강 답변 “운동”</td>
        </tr>
        <tr class="survey-title">
          <td style="width: 20%">자다가 자주 깬다</td>
          <td style="width: 20%">쉽게 잠들기 어렵다</td>
          <td style="width: 20%">하루 총 수면</td>
          <td style="width: 20%">주3회이상 운동을 한다</td>
          <td style="width: 20%">운동을 좋아하는 편이다</td>
        </tr>
        <tr>
          <td>{{ survey.sleep1 }}</td>
          <td>{{ survey.sleep2 }}</td>
          <td>{{ survey.sleep3 }}시간</td>
          <td>{{ survey.exercise1 }}</td>
          <td>{{ survey.exercise2 }}</td>
        </tr>
        </tbody>
      </table>
      <table class="surveytb surveytb_2">
        <tbody>
        <tr class="cate">
          <td colspan="4">건강 답변 “음주흡연”</td>
        </tr>
        <tr class="survey-title">
          <td style="width: 25%">술을 좋아하는편이다</td>
          <td style="width: 25%">독한술 보다는 맥주 · 와인이 좋다</td>
          <td style="width: 25%">주 2회이상 마신다</td>
          <td>담배를 1주에 1갑이상 핀다</td>
        </tr>
        <tr>
          <td>{{ survey.drinksmoke1 }}</td>
          <td>{{ survey.drinksmoke2 }}</td>
          <td>{{ survey.drinksmoke3 }}</td>
          <td>{{ survey.drinksmoke4 }}</td>
        </tr>
        </tbody>
      </table>
      <table class="surveytb surveytb_2">
        <tbody>
        <tr class="cate">
          <td colspan="4">건강 답변 “감성”</td>
        </tr>
        <tr class="survey-title">
          <td style="width: 25%">조용하고 침착하다</td>
          <td style="width: 25%">화가 많은 편이다</td>
          <td style="width: 25%">잘 웃는다</td>
          <td>스트레스가 많은편이다</td>
        </tr>
        <tr>
          <td>{{ survey.sens1 }}점</td>
          <td>{{ survey.sens2 }}점</td>
          <td>{{ survey.sens3 }}점</td>
          <td>{{ survey.sens4 }}점</td>
        </tr>
        </tbody>
      </table>
      <table class="surveytb surveytb_2">
        <tbody>
        <tr class="cate">
          <td style="width: 33%">최근 2주이내 병원에 간적이 있다면</td>
          <td style="width: 33%">가장 빈번하게 병원을 찾는 이유는?</td>
          <td>만성질환이 있나요?</td>
        </tr>
        <tr>
          <td>{{ survey.disease_weekly }}</td>
          <td>{{ survey.disease_frequently }}</td>
          <td>{{ survey.disease_chronic }}</td>
        </tr>
        </tbody>
      </table>
      <table class="surveytb surveytb_2">
        <tbody>
        <colgroup>
          <col width="">
          <col width="16.6%">
          <col width="16.6%">
          <col width="16.6%">
          <col width="16.6%">
          <col width="16.6%">
        </colgroup>
        <tr class="cate">
          <td colspan="6">내가 생각하는 내 건강</td>
        </tr>
        <tr class="survey-title">
          <td>근력</td>
          <td style="width: 16.6%">순환기</td>
          <td style="width: 16.6%">간</td>
          <td style="width: 16.6%">신장/비뇨기</td>
          <td style="width: 16.6%">소화기</td>
          <td style="width: 16.6%">피부</td>
        </tr>
        <tr>
          <td>{{ survey.health1 }}</td>
          <td>{{ survey.health2 }}</td>
          <td>{{ survey.health3 }}</td>
          <td>{{ survey.health4 }}</td>
          <td>{{ survey.health5 }}</td>
          <td>{{ survey.health6 }}</td>
        </tr>
        </tbody>
      </table>
      <table class="surveytb surveytb_2">
        <tbody></tbody>
        <tbody>
        <tr class="cate">
          <td colspan="5">건강식품이나 상시 복용중인 약품</td>
        </tr>
        <tr class="survey-title">
          <td>프로바이오틱스</td>
          <td style="width: 20%">홍삼</td>
          <td style="width: 20%">한약</td>
          <td style="width: 20%">비타민</td>
          <td style="width: 20%">기타</td>
        </tr>
        <tr>
          <td>{{ survey.healthfood1 || '-' }}</td>
          <td>{{ survey.healthfood2 || '-' }}</td>
          <td>{{ survey.healthfood3 || '-' }}</td>
          <td>{{ survey.healthfood4 || '-' }}</td>
          <td>{{ survey.healthfood5 || '-' }}</td>
        </tr>
        </tbody>
      </table>
      <table class="surveytb surveytb_2">
        <tbody></tbody>
        <tbody>
        <tr class="cate">
          <td colspan="6">가족력</td>
        </tr>
        <tr class="survey-title">
          <td>혈관 심장질환<span>(고혈압, 고지혈증, 심근경색 등)</span></td>
          <td style="width: 16.6%">위장관 질환<span>(위염, 궤양, 과민성대장염 등)</span></td>
          <td style="width: 16.6%">호흡기 질환<span>(폐렴, 천식, 만성폐질환 등)</span></td>
          <td style="width: 16.6%">신장질환<span>(신부전, 신증후군 등)</span></td>
          <td style="width: 16.6%">알레르기 질환<span>(신부전, 신증후군 등)</span></td>
          <td style="width: 16.6%">내분비<span>(비만, 당뇨, 갑상선 등)</span></td>
        </tr>
        <tr>
          <td>{{ survey.hypertension || '-' }}</td>
          <td>{{ survey.heart_disease || '-' }}</td>
          <td>{{ survey.stroke || '-' }}</td>
          <td>{{ survey.breast_cancer || '-' }}</td>
          <td>{{ survey.lung_cancer || '-' }}</td>
          <td>{{ survey.diabetes || '-' }}</td>
        </tr>
        </tbody>
      </table>
      <table class="surveytb surveytb_2">
        <tbody></tbody>
        <tbody>
        <tr class="survey-title">
          <td>뇌질환<span>(치매, 뇌졸중, 간질 등)</span></td>
          <td style="width: 16.6%">각종 암<span>(폐암, 위암, 간암 등)</span></td>
          <td style="width: 16.6%">류마치스, 면역질환</td>
          <td style="width: 16.6%">감염질환</td>
          <td style="width: 16.6%">기타</td>
          <td style="width: 16.6%"></td>
        </tr>
        <tr>
          <td>{{ survey.hyperlipidemia || '-' }}</td>
          <td>{{ survey.osteoporosis || '-' }}</td>
          <td>{{ survey.colorectal_cancer || '-' }}</td>
          <td>{{ survey.thyroid_cancer || '-' }}</td>
          <td>{{ survey.stomach_cancer || '-' }}</td>
          <td>-</td>
        </tr>
        </tbody>
      </table>
    </section>
    <!-- 페이지끝 -->
<!--    <v-chart  :options="heightChart"
             :init-options="{
                            width: 600,
                            height : 500
                            }"
             theme='shine' />-->
</template>

<script>
import moment from 'moment'

export default {
  props: {
    data : Object,
    code : String,
    topMargin: {
      type : Boolean,
      default: true
    }
  },
  data() {
    return {
      moment: moment,
      chartSize : { width: 500, height: 500 }
    }
  },
  methods: {
  },
  computed : {
    sample() {
      return this.$store.state.sample;
    },
    survey() {
      return this.$store.state.survey;
    },
    heightChart() {
      return this.$store.state.heightChartData;
    },

  }
}
</script>
<style lang="scss" scoped>

section.contents {
  position:relative; height:1569px; margin:0; overflow:hidden;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.45;
  letter-spacing:-0.5px;
  color: #313132;
  font-family: 'GmarketSans';
}

@font-face {
  font-family: 'GmarketSans';
  font-weight: 300;
  font-style: normal;
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/gmarket/GmarketSansLight.eot');
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/gmarket/GmarketSansLight.eot?#iefix') format('embedded-opentype'),
  url('https://cdn.jsdelivr.net/gh/webfontworld/gmarket/GmarketSansLight.woff2') format('woff2'),
  url('https://cdn.jsdelivr.net/gh/webfontworld/gmarket/GmarketSansLight.woff') format('woff'),
  url('https://cdn.jsdelivr.net/gh/webfontworld/gmarket/GmarketSansLight.ttf') format("truetype");
  font-display: swap;
}
@font-face {
  font-family: 'GmarketSans';
  font-weight: 500;
  font-style: normal;
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/gmarket/GmarketSansMedium.eot');
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/gmarket/GmarketSansMedium.eot?#iefix') format('embedded-opentype'),
  url('https://cdn.jsdelivr.net/gh/webfontworld/gmarket/GmarketSansMedium.woff2') format('woff2'),
  url('https://cdn.jsdelivr.net/gh/webfontworld/gmarket/GmarketSansMedium.woff') format('woff'),
  url('https://cdn.jsdelivr.net/gh/webfontworld/gmarket/GmarketSansMedium.ttf') format("truetype");
  font-display: swap;
}
@font-face {
  font-family: 'GmarketSans';
  font-weight: 700;
  font-style: normal;
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/gmarket/GmarketSansBold.eot');
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/gmarket/GmarketSansBold.eot?#iefix') format('embedded-opentype'),
  url('https://cdn.jsdelivr.net/gh/webfontworld/gmarket/GmarketSansBold.woff2') format('woff2'),
  url('https://cdn.jsdelivr.net/gh/webfontworld/gmarket/GmarketSansBold.woff') format('woff'),
  url('https://cdn.jsdelivr.net/gh/webfontworld/gmarket/GmarketSansBold.ttf') format("truetype");
  font-display: swap;
}
.survey-title {font-size:15px;font-weight:500;color:#333;vertical-align:middle;}
.survey-span {display:block;letter-spacing:-2px;color:#999;line-height:13px;font-weight:100;}
section .surveytb {width:1052px;border-collapse:collapse;font-size:15px;margin:auto;}
section .surveytb_2 {margin-top:-1px;}
section .choice td {color:#a9a9a9}
section .choice td em {color:#333;font-weight:500;font-style:normal;}
section .surveytb td {text-align:center;padding:8px 0px 7px;border:1px solid #ccc;}
section .surveytb td:last-child {border:none;}
section .surveytb td.food {padding:7px 7px;}
section .surveytb tr {border:1px solid #ccc;}
section .surveytb tr.cate {background:#ededed;}
section .surveytb tr.cate td {font-size:16px;padding:8px 0;font-weight:500;color:#000;}
section .surveytb tr.title td {font-size:15px;font-family: 'GmarketSans';font-weight:500;color:#333;vertical-align:middle;}
section .surveytb tr.survey-title  td span {display:block;letter-spacing:-2px;color:#999;line-height:13px;font-weight:100;}
section.sv_baby table td {padding:10px 0px 9px !important;}
section.sv_child table td {padding:9px 0px 9px !important;}
</style>