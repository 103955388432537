<template>
  <section class="contents" style="height:1550px;">
    <div class="header_03">
      <div class="inner" style="height: 350px">
        <div class="tit_box">
          <p class="tit_e">YOUR TYPE AND FOOD RECOMMENDATIONS</p>
          <p class="tit_k2">당신의 타입과 식이 처방</p>
          <p class="sub">AIBIOTICS는 장미생물 검사결과와 식이취향, 설문을 AI로 분석하여<br />16가지의 AIBHBI [ 아이브 헬스 밸런스 인덱스] 를 만들었습니다.<br />당신의 타입과 맞춤형 식단을 확인해 보세요.</p>
        </div>
      </div>
    </div>
    <div class="inner_01">
      <div class="c_wrap">
        <p class="type_name" style="margin: 0px">{{type}}</p>

        <p class="food_type_desc" v-html="recommendation.desc"></p>
        <div class="food_rcm food_level1">
          <h3 class="level1"><span>적극추천</span></h3>
          <ul>
            <li v-for="index in recommendation.best" style="height: 87px;-webkit-box-orient: vertical;" :key="index">
              <p :style="index.style"></p><span class="kind_str" >{{index.kindsStr}}</span>
            </li>
          </ul>
        </div>
        <div class="food_rcm food_level2">
          <h3 class="level2"><span>약한추천</span></h3>
          <ul>
            <li v-for="index in recommendation.weak" style="height: 87px" :key="index">
              <p :style="index.style"></p><span class="kind_str">{{index.kindsStr}}</span>
            </li>
          </ul>
        </div>
        <div class="food_rcm food_level3">
          <h3 class="level3"><span>소극적추천</span></h3>
          <ul>
            <li v-for="index in recommendation.passive" style="height: 87px" :key="index">
              <p :style="index.style"></p><span class="kind_str">{{index.kindsStr}}</span>
            </li>
          </ul>
        </div>
        <div class="food_rcm food_level4 mg0">
          <h3 class="level4"><span>비추천</span></h3>
          <ul>
            <li v-for="index in recommendation.not" style="height: 87px" :key="index">
              <p :style="index.style"></p><span class="kind_str">{{index.kindsStr}}</span>
            </li>
          </ul>
        </div>
      </div>
      <slot name="aibhbiComment"> </slot>
      <div class="footer_02">
        <span class="logo"><img src="/reportV2/img/logo_02.png" alt="logo"/></span>
        <span class="page"><span>02</span></span>
      </div>
    </div>
  </section>
  <!-- 페이지 끝 -->
</template>

<script>
import axios from 'axios'

export default {
  name: 'Aibhbi',
  data () {
    return {
      type : "선택되지 않았습니다.",
      recommendation : {
        best : [{ style : "background-position:0 0", kindsStr : "당신의 타입을 선택 하지 않았습니다."} ],
        weak : [{ style : "background-position:0 0", kindsStr : "당신의 타입을 선택 하지 않았습니다."} ],
        passive : [{ style : "background-position:0 0", kindsStr : "당신의 타입을 선택 하지 않았습니다."} ],
        not : [{ style : "background-position:0 0", kindsStr : "당신의 타입을 선택 하지 않았습니다."} ],
        desc : [{ style : "background-position:0 0", kindsStr : "당신의 타입을 선택 하지 않았습니다."} ]
      },
      imageStyle : {
        grain : "background-position:0 0",
        rice : "background-position:-200px 0",
        barley : "background-position:-200px -700px",
        noodle : "background-position:-300px 0",
        corn : "background-position:-100px 0px",
        bread : "background-position:0px -500px",
        fruit : "background-position:0 -800px",
        berry : "background-position:-100px -800px",
        berry200 : "background-position:-200px -800px",
        mango : "background-position:-200px -1000px",
        fruit1 : "background-position:-200px -800px",
        fruit2 : "background-position:-300px -800px",
        apple : "background-position:-300px -800px",
        waterMelon : "background-position:-400px -800px",
        fruit3 : "background-position:-300px -1000px",
        banana : "background-position:-0px -1000px",
        seaweed : "background-position:0 -100px",
        seaweed100 : "background-position:-100px -100px",
        seaweed200 : "background-position:-200px -100px",
        seaweed1 : "background-position:-100px -600px",
        seaweed_400_600 : "background-position:-400px -600px",
        nuts : "background-position:0 -1200px",
        nuts1 : "background-position:-100px -1200px",
        almond : "background-position:0px -1200px",
        peanut: "background-position:-200px -1200px",
        vegetables : "background-position:-200px -1100px",
        carrot: "background-position:-100px -1100px",
        potato: "background-position:-0px -1100px",
        onion: "background-position:-100px -1000px",
        garlic: "background-position:-200px -1100px",
        tomato: "background-position:-300px -200px",
        vegetables1 : "background-position:-300px -1100px",
        vegetables2 : "background-position:-400px -1100px",
        sweetener : "background-position:-100px -600px",
        sweetener2 : "background-position:-300px -600px",
        sweetener200 : "background-position:-200px -600px",
        sweetener3 : "background-position:-300px -600px",
        sweetener400 : "background-position:-400px -600px",
        sugars : "background-position:-200px -600px",
        scenicCheese: "background-position:-100px -1300px",
        milkProducts2 : "background-position:-100px -500px",
        milkProducts1 : "background-position:-300px -1300px",
        butter: "background-position:-200px -500px",
        beans : "background-position:0px -700px",
        beans1 : "background-position:0px -400px",
        beans2 : "background-position:-200px -1300px",
        beans3 : "background-position:0 -700px",
        gangnamBeans : "background-position:-100px -700px",
        processedFood : "background-position:0 -1300px",
        whippedCream : "background-position:-400px -1300px",
        meatFish1Turbulence : "background-position:-300px -800px",
        meatFish3Turbulence : "background-position:0px -1300px",
        meatFish1Turbulence1 : "background-position:-100px -900px",
        meatFishTurbulence : "background-position:0px -200px",
        meatFish2Turbulence2 : "background-position:0px -200px",
        egg: "background-position:-200px -200px",
        meat: "background-position:0px -900px",
        pig: "background-position:-100px -900px",
      },
      aibhbiFullDesc : {
        FMGS : `
육식위주의 비만타입입니다. 소화가 잘되서 식욕이 넘치는 편입니다.
한때 운동을 한적이 있거나 상당히 활동적인 편이며, 스스로 건강하다고 자만하기 때문에 관리가 소홀해질 가능성이 큽니다.
특히 고지혈증과 이와 관련된 성인병에 주의해야 하며, 성인 남자의 경우에는 대장 건강에도 주의해야 하는 타입니다.
        `,
        FMGW : `
육식위주의 비만이며 소화가 잘되서 식욕이 넘치지만 자주 아프고 잔병치레가 많은 편입니다.
덩치에 맞지 않게 잘 아픈 편이라 '물살'이라고 놀림받기도 합니다.
건강관리가 소홀해져 있으며, 운동이 필요한건 알지만 가능하면 먹어서 해결할려고 하는 게으른 스타일인 경우가 많습니다.
먹어서 해결되기 보다는 절제가 더 중요할 수 있습니다.
        `,
        FMBS : `
육식을 좋아하지만 소화력이 좋지 않습니다. <br/>
배앓이가 잦은 편인데 장내 유해균이 많은 경우도 있지만 소화능력에 비해 식탐이 많은 경우가 더 많습니다. <br/>
과식에 의한 배탈이 잦은 편이며, 심리적인 요인 역시 상당히 작용하는 편이기 때문에 식사양을 조절하기 위한 노력이 필요합니다.
포만감을 줄 수 있는 저칼로리 음식이 심리적 안정감을 줄 수 있습니다.
        `,
        FMBW : `
육식을 좋아하지만 소화가 안되서 힘들고 운동량도 부족한 흔히 말하는 물살체질입니다.
가장 관리가 필요한 상황이며, 특히 당뇨나 갑상선과 같은 내분비 질환에 취약합니다.
식사나 약으로 해결하기 어려운 경우가 많으며 생활습관부터 바꾸는게 가장 중요합니다.
체중조절과 더불어 적절한 운동과 식단조절이 시급한 상황입니다.
        `,
        FVGS : `
채식위주로 식사를 조절하는데 살이 빠지지 않아 고민인 경우가 많습니다. <br/>
소화도 잘되고 아픈데도 없으니 굳이 살을 빼야 하나 싶지만 의외로 성인병에 취약한 상태이므로 조절이 필요합니다.
탄수화물에 중독된 경우가 많으며 면이나 빵을 사랑하는 경우가 많습니다.
제분기술의 발달과 더불어 당뇨가 증가한 사실을 비추어 볼 때 이 타입은 특히 당뇨관련 인자에 주의해야 합니다.
        `,
        FVGW : `
고기도 안먹는데 살이 안빠져서 고민이 많은 타입입니다. <br/>
소화는 잘되는데 의외로 잘 아픈 편입니다. 민감한 경우가 많고 건강 염려증이 있기도 합니다.
면류나 빵류를 즐기는 편이며, 알레르기가 있는 경우가 많습니다. 편중된 식습관은 다양한 성인병으로 연결될 수 있습니다. <br/>
컨디션이 좋지 않다고 무작정 약부터 찾지 말고 다른 방법[스트레스 해소, 운동, 취미]을 찾아봐야 합니다.
        `,
        FVBS : `
빵이나 라면 같은 밀가루 음식과 단 음식를 즐기는 타입입니다.
가끔 배가 아프지만 금방금방 좋아지다 보니 그러려니 하고 참습니다. 장이 점점 약해지고 있는 중입니다.
살찌는게 두려워 무가당 탄산음료를 찾지만 사실 다이어트에 도움이 되지 않을 뿐 아니라 장에 유해균증식을 도울 뿐입니다.
특히 당뇨, 고혈압에 취약합니다.
        `,
        FVBW : `
빵이나 라면 같은 밀가루 음식과 단 음료를 즐기는 타입입니다. 안좋은 식습관에다가 운동을 싫어하는 경우가 많습니다.
아파서 운동 안하고, 안아프면 운동할 필요가 없다고 생각합니다.
단음식을 좋아하는 경우가 많기 때문에 당분과 더불어 합성 감미료 역시 아주 조심해야 합니다.
무설탕 음식에 든 각종 감미료는 장 건강에 위협이 될 수 있습니다.
장만 좋지 않은게 아니기 때문에 다른 질병과의 연관성을 주의해야 합니다.
        `,
        SMGS : `
건강한 타입입니다. 음식과 생활을 절제하는 편일 수도 있지만 타고난 건강체질로 먹어도 찌지 않는 경우도 많습니다.
지금 문제가 없다 해도 나이가 들수록 관리를 더 잘 해야합니다.
음식의 균형을 유지하는 것이 중요합니다. <br/> 나이가 들수록 육식을 줄이는게 좋습니다.
        `,
        SMGW : `
날씬하고 다 건강한건 아닙니다. 덜먹어서 날씬하다면 장에는 문제가 없지만 콜레스테롤이나 고지혈증과 같은 질환에 조심해야 하며 반드시 적절한 운동이 필요한 타입입니다.
이 타입에서는 운동하는 경우와 운동하지 않는 경우의 차이가 큽니다. 운동없이 체중만 조절하고 있다면 나이들수록 쉽게 아플 수 있습니다. <br/>
반대로 식욕이 없는 경우라면 적당량의 조미료가 입맛을 돋구는데 도움이 될 수 있습니다.
        `,
        SMBS : `
식이섬유가 부족한 소식에 적응된 경우 변비에 시달리고 있을 가능성이 있습 니다. <br/> 혹은 잦은 장트러블로 고생하는 경우도 있습니다.
육식위주의 식단은 배변주기를 길게 만드는 경우와 장내세균의 <br/>균형을 잃어 과민한 장이 되는 두 가지의 경우가 유발됩니다. <br/>
장의 불편함은 장의 유해균의 영향일 수도 있지만 장 정체시간이 길어지는 이유일 수도 있습니다.
        `,
        SMBW : `
고기를 좋아하는데 소화는 안되고 자주 아프기까지 합니다. 소화가 안되면 만사가 귀찮고 기운이 없습니다. 매우 피해야 하는 타입입니다.
육류 섭취를 줄이기보다는 채식 섭취량을 보충해야 해야 하며, 단백질 보충은 콩류나 <br/> 생선으로 대체하는 방법도 좋겠습니다.
식탐이 없는 입이 짧은 경우가 많은데 먹는 즐거움을 알아가면 좋겠습니다.
        `,
        SVGS : `
채식을 즐기고 장 건강도 양호하고 불편한데가 없습니다. 운동을 즐기는 경우가 많습니다. <br/>
성인에게 추천할 만큼 적절하지만 청소년기에는 단백질 섭취를 좀 더 하는게 좋겠습니다.
지나친 채식일변도는 <br/> 자칫 영양 불균형을 초래할지도 모릅니다. 지나친 채식의 편중은 몸 안에 사리를 만들 수도 있습니다. <br/>
식욕이 없다면 적당한 조미료는 도움이 될 수 있습니다.
        `,
        SVGW : `
소식으로 단련되어 식탐이 없지만 건강에 대한 자신이 없습니다.
건강에 대한 지나친 걱정일 수도 있지만 대사증후군이나 다른 원인이 있을지도 모르니 주기적인 건강 검진과 무엇보다 음식을 잘 먹어야 합니다. 지나친 채식 일변도 <br/> 식사는 자칫 영향의 불균형을 초래할 수도 있습니다.
인간은 잡식 동물이므로 동물성 단백질도 필요합니다.
        `,
        SVBS : `
장 트러블이 잦고 먹어도 먹어도 소화가 잘 안되지만 의외로 잔병이 없는 타입입니다.
소화기능이 떨어진 이유는 <br/> 선천적일 수도 있지만 거친 음식에 대한 경험치가 낮아 적응력이 낮은 경우로 볼 수도 있습니다. <br/>
장 건강의 문제일 수도 있지만 때론 위장의 문제인 경우도 흔하기 때문에 증상으로 단정하기 어렵습니다. <br/>
근력이 약한 경우가 대부분입니다. 운동은 근육을 키우기도 하지만 입맛을 돌게하는 순기능도 있습니다.
        `,
        SVBW : `
먹는게 별로 즐겁지 않은 경우가 많습니다. 냄새에 민감하고 간단하게 먹을 수 있는 음식을 찾다 보니 배변주기가 <br/> 길어지고 변비도 잦습니다.
소화력이 좋지 않아 조금만 많이 먹어도 배탈이 나는 경우가 많습니다. 습관의 문제이기도 하지만 타고난 면도 없지 않습니다.
최소 배변주기가 2일을 넘기지 않도록 식사량을 조절하는게 좋습니다.
면역력을 증강시키고 적절한 단백질 보충과 더불어 운동이 필요합니다. 운동으로 몸이 튼튼해지면 세상이 달라집니다.
        `
      }

    }
  },
  methods: {
    changeTypeContents(type, desc) {
      this.type = type;

      this.recommendation.desc = this.aibhbiFullDesc[type];

      switch (type) {
        case "FMGS" :
          this.recommendation.best = [
            { style : this.imageStyle.grain, kindsStr : "귀리, 보리, 현미, 퀴노아, 통밀"},
            { style : this.imageStyle.fruit, kindsStr : "레몬, 자두, 살구, 체리, 리치"},
            { style : this.imageStyle.seaweed, kindsStr : "파래, 매생이, 청각, 미역, 톳, 다시마"},
            { style : this.imageStyle.nuts, kindsStr : "땅콩, 아몬드, 호두, 캐슈너트, 브라질너트, 은행"},
            { style : this.imageStyle.vegetables, kindsStr : "마늘, 연근, 당근, 버섯, 양파, 무, 오이, 배추"},
          ]

          this.recommendation.weak= [
            { style : this.imageStyle.fruit1, kindsStr : "귤, 오렌지, 키위, 자몽, 배리류, 딸기"},
            { style : this.imageStyle.nuts1, kindsStr : "해바라기씨, 잣, 호박씨, 마카다미아, 피칸"},
            { style : this.imageStyle.seaweed1, kindsStr : "다시마, 굴소스, 미원, 맛소금, 기타 조미료"},
            { style : this.imageStyle.vegetables1, kindsStr : "브로콜리, 시금치, 양배추, 가지, 파류, 호박, 생강"},
            { style : this.imageStyle.beans3, kindsStr : "완두콩, 대두, 병아리콩, 랜틸콩, 강남콩"},
          ]

          this.recommendation.passive = [
            { style : this.imageStyle.fruit2, kindsStr : "복숭아, 푸룬(말린자두), 아포카도, 수박, 망고, 사과, 배"},
            { style : this.imageStyle.sugars, kindsStr : "당류, 사카린, 당알코올, 아스팜탐, 아세설팜"},
            { style : this.imageStyle.scenicCheese, kindsStr : "경치즈, 체다치즈, 두유, 우유, 모짜렐라치즈, 까망베르치즈, 연치즈, 생치즈"},
            { style : this.imageStyle.potato, kindsStr : "토마토, 감자, 고구마, 덜익은 과일, 채소"},
            { style : this.imageStyle.beans2, kindsStr : "들기름, 참기름, 올리브오일, 코코넛오일, 해초유, 아보카도오일"},
          ]

          this.recommendation.not = [
            { style : this.imageStyle.bread, kindsStr : "찹쌀, 국수류, 빵류"},
            { style : this.imageStyle.banana, kindsStr : "석류, 적포도, 바나나, 감"},
            { style : this.imageStyle.meat, kindsStr : "쇠고기, 돼지고기, 양고기"},
            { style : this.imageStyle.processedFood, kindsStr : "소시지, 햄, 베이컨"},
            { style : this.imageStyle.whippedCream, kindsStr : "버터, 아이스크림, 생크림, 연치즈, 생치즈, 생크림"},
          ]

          break;
        case "FMGW":


          this.recommendation.best = [
            { style : this.imageStyle.grain, kindsStr : "귀리, 보리, 현미, 퀴노아, 통밀"},
            { style : this.imageStyle.berry, kindsStr : "귤, 오렌지, 베리류, 키위, 딸기, 자두, 살구, 체리, 리치"},
            { style : this.imageStyle.meatFishTurbulence, kindsStr : "계란, 메추리알, 오리알, 타조알, 닭, 칠면조, 오리, 조개류, 연어알, 날치알"},
            { style : this.imageStyle.carrot, kindsStr : "마늘, 연근, 당근, 브로콜리, 시금치, 양배추, 버섯, 양파, 가지, 파류, 호박, 생강, 무, 오이, 배추"},
            { style : this.imageStyle.beans1, kindsStr : "두부, 청국장, 된장, 낫토"},
          ]

          this.recommendation.weak = [
            { style : this.imageStyle.fruit, kindsStr : "자몽, 석류, 레몬, 복숭아, 푸룬, 아보카도, 사과"},
            { style : this.imageStyle.seaweed, kindsStr : "파레, 매생이, 청각, 미역, 톳, 다시마"},
            { style : this.imageStyle.nuts, kindsStr : "아몬드, 해바라기씨, 잣, 호박씨, 마카다미아, 피칸"},
            { style : this.imageStyle.vegetables2, kindsStr : "청경채, 양상추, 고추, 근대, 토란, 피망, 죽순"},
            { style : this.imageStyle.beans2, kindsStr : "들기름, 참기름, 올리브오일, 코코넛오일, 해조유, 아보카도오일"},
          ]

          this.recommendation.passive = [
            { style : this.imageStyle.banana, kindsStr : "적포도, 바나나, 수박, 망고, 배, 감"},
            { style : this.imageStyle.sugars, kindsStr : "당류, 사카린, 올리고당, 당알코올, 아스팜탐, 아세설팜"},
            { style : this.imageStyle.milkProducts1, kindsStr : "우유, 모짜렐라치즈, 까망베르치즈, 버터, 연치즈, 생치즈"},
            { style : this.imageStyle.potato, kindsStr : "토마토, 감자, 고구마, 덜익은 과일, 채소"},
            { style : this.imageStyle.beans, kindsStr : "완두콩, 대두, 병아리콩, 렌틸콩, 강남콩"},
          ]

          this.recommendation.not = [
            { style : this.imageStyle.bread, kindsStr : "국수류, 빵류"},
            { style : this.imageStyle.peanut, kindsStr : "땅콩, 호두"},
            { style : this.imageStyle.meat, kindsStr : "쇠고기"},
            { style : this.imageStyle.processedFood, kindsStr : "소시지, 햄, 베이컨"},
            { style : this.imageStyle.whippedCream, kindsStr : "아이스크림, 생크림, 연치즈, 생치즈"},
          ]


          break;

        case "FMBS":

          this.recommendation.best = [
            { style : this.imageStyle.berry, kindsStr : "베리류, 레몬, 자두, 살구, 체리, 리치"},
            { style : this.imageStyle.seaweed100, kindsStr : "미역, 톳, 다시마, 우뭇가사리, 꼬시레기"},
            { style : this.imageStyle.almond, kindsStr : "아몬드, 해바라기씨, 캐슈너트, 브라질너트, 은행"},
            { style : this.imageStyle.carrot, kindsStr : "마늘, 당근, 양배추, 버섯, 가지, 무, 오이, 배추"}
          ]
          this.recommendation.weak = [
            { style : this.imageStyle.grain, kindsStr : "귀리, 퀴노아, 통밀, 일반쌀"},
            { style : this.imageStyle.fruit1, kindsStr : "키위, 자몽, 복숭아, 딸기, 푸룬, 아보카도"},
            { style : this.imageStyle.sweetener, kindsStr : "다시다, 굴소스, 미원, 맛소금, 기타 조미료"},
            { style : this.imageStyle.vegetables1, kindsStr : "브로콜리, 시금치, 양파, 파류, 호박, 생각, 청경채, 양상추, 고추, 근대, 토란, 피망, 죽순"},
            { style : this.imageStyle.beans2, kindsStr : "참기름, 올리브오일, 코코넛오일, 해조유, 아보카도오일"},
          ]
          this.recommendation.passive = [
            { style : this.imageStyle.banana, kindsStr : "귤, 오렌지, 석류, 적포도, 바나나, 수박, 사과, 배, 감"},
            { style : this.imageStyle.sweetener2, kindsStr : "올리고당(프락토올리고당, 대두올리고당, 갈락토올리고당), 당알코올(자일리톨, 솔비톨)"},
            { style : this.imageStyle.meatFish1Turbulence1, kindsStr : "쇠고기, 돼지고기, 양고기, 명란젓"},
            { style : this.imageStyle.whippedCream, kindsStr : "우유, 아이스크림, 생크림, 연치즈, 생치즈,"},
            { style : this.imageStyle.potato, kindsStr : "토마토, 감자, 고구마"},
          ]
          this.recommendation.not = [
            { style : this.imageStyle.bread, kindsStr : "보리, 국수류, 빵류"},
            { style : this.imageStyle.peanut, kindsStr : "땅콩, 호두"},
            { style : this.imageStyle.sugars, kindsStr : "당류, 사카린, 아스팜탐, 아세설팜"},
            { style : this.imageStyle.meatFish3Turbulence, kindsStr : "소시지, 햄, 베이컨"},
            { style : this.imageStyle.beans3, kindsStr : "완두콩, 대두, 병아리콩, 렌틸콩, 강남콩"}
          ]


          break;
        case "FMBW":
          this.recommendation.best = [
            { style : this.imageStyle.corn, kindsStr : "현미,  옥수수,  수수,  조"},
            { style : this.imageStyle.fruit1, kindsStr : "키위, 자몽, 베리류(블루베리, 블랙베리, 아사이베리, 크렌베리, 라즈베리) , 레몬,  딸기,  자두,  살구,  체리,  리치"},
            { style : this.imageStyle.seaweed, kindsStr : "파래, 매생이 , 청각, 김, 우뭇가사리, 꼬시래기, 우뭇가사리, 꼬시래기"},
            { style : this.imageStyle.egg, kindsStr : "계란, 메추리알, 오리알, 타조알, 캐비어,  오리,  조개류, 연어알, 날치알"},
            { style : this.imageStyle.carrot, kindsStr : "브로콜리,  시금치,  가지,  호박,  생강,  당근,  무,  오이,  배추,  근대,  토란,  피망"},
          ]

          this.recommendation.weak = [
            { style : this.imageStyle.grain, kindsStr : "귀리,  퀴노아 , 통밀, 일반쌀"},
            { style : this.imageStyle.almond, kindsStr : "아몬드, 호두, 마카다미아"},
            { style : this.imageStyle.sweetener, kindsStr : "다시다, 굴소스, 미원, 맛소금, 기타 조미료"},
            { style : this.imageStyle.garlic, kindsStr : "마늘,  토마토,  파류, 감자,  청경채,  양상추,  토란,  죽순,  고구마"},
            { style : this.imageStyle.beans2, kindsStr : "들기름,  참기름,  올리브오일,  코코넛오일,  해조유, 아보카도오일"},
          ]

          this.recommendation.passive = [
            { style : this.imageStyle.banana, kindsStr : "귤, 오렌지, 석류, 적포도, 바나나,  복숭아,  아보카도, 수박,  감"},
            { style : this.imageStyle.seaweed100, kindsStr : "미역, 톳, 다시마"},
            { style : this.imageStyle.nuts1, kindsStr : "해바라기씨, 잣, 호박씨"},
            { style : this.imageStyle.milkProducts1, kindsStr : " 모짜렐라치즈,  까망베르 치즈,  버터"},
            { style : this.imageStyle.onion, kindsStr : "버섯,  양파,  고추"},
          ]

          this.recommendation.not = [
            { style : this.imageStyle.fruit2, kindsStr : "망고,  사과,  배"},
            { style : this.imageStyle.peanut, kindsStr : "땅콩"},
            { style : this.imageStyle.sugars, kindsStr : "당류(설탕, 물엿, 유당,포도당, 과당), 올리고당(프락토올리고당, 대두올리고당, 갈락토올리당), 사카린, 당알코올(자일리톨, 솔비톨), 아스팜탐, 아세설팜"},
            { style : this.imageStyle.pig, kindsStr : "쇠고기, 돼지고기, 양고기"},
            { style : this.imageStyle.meatFish3Turbulence, kindsStr : "소시지, 햄, 베이컨"},
          ]

          break;

        case "FVGS":
          this.recommendation.best = [
            { style : this.imageStyle.grain, kindsStr : "귀리,  현미,  퀴노아"},
            { style : this.imageStyle.berry, kindsStr : "베리류(블루베리, 블랙베리, 아사이베리, 크렌베리, 라즈베리) , 레몬, 자몽, 석류,  자두,  살구,  체리,  리치"},
            { style : this.imageStyle.carrot, kindsStr : "마늘,  연근,  당근,  양배추,  버섯,  양파,  가지,  파류,  호박,  생강,  브로콜리,  시금치,  무,  오이,  배추"},
            { style : this.imageStyle.beans1, kindsStr : "두부, 청국장, 된장, 낫토"}
          ]

          this.recommendation.weak = [
            { style : this.imageStyle.fruit2, kindsStr : "키위,  딸기,  아보카도,  사과,  배"},
            { style : this.imageStyle.meatFish2Turbulence2, kindsStr : "닭,  칠면조, 연어알, 날치알"},
            { style : this.imageStyle.scenicCheese, kindsStr : "경치즈, 체다치즈, 유당이 없는 우유(락토프리우유), 두유"},
            { style : this.imageStyle.vegetables2, kindsStr : "청경채,  양상추,  고추,  근대,  토란,  피망,  토란,  죽순"},
            { style : this.imageStyle.beans2, kindsStr : "들기름,  참기름,  올리브오일,  코코넛오일,  해조유, 아보카도오일"},
          ]

          this.recommendation.passive = [
            { style : this.imageStyle.fruit, kindsStr : "귤, 오렌지,  복숭아,  감"},
            { style : this.imageStyle.almond, kindsStr : "아몬드, 해바라기씨, 잣, 호박씨, 마카다미아, 피칸"},
            { style : this.imageStyle.meat, kindsStr : "쇠고기, 돼지고기, 양고기, 명란젓, 소시지, 햄, 베이컨, 햄, 베이컨"},
            { style : this.imageStyle.potato, kindsStr : "토마토, 감자,  고구마, 덜익은 과일, 채소"},
            { style : this.imageStyle.beans3, kindsStr : "완두콩,  대두,  병아리콩,  렌틸콩,  강낭콩"},
          ]

          this.recommendation.not = [
            { style : this.imageStyle.bread, kindsStr : "찹쌀,  국수류,  빵류"},
            { style : this.imageStyle.banana, kindsStr : "적포도, 바나나,  푸룬(말린자두), 수박,  망고"},
            { style : this.imageStyle.peanut, kindsStr : "땅콩, 호두"},
            { style : this.imageStyle.seaweed_400_600, kindsStr : "아세설팜"},
            { style : this.imageStyle.whippedCream, kindsStr : "아이스크림, 생크림, 연치즈, 생치즈, 생크림"}
          ]

          break;
        case "FVGW":
          this.recommendation.best = [
            { style : this.imageStyle.grain, kindsStr : "귀리,  현미,  퀴노아 , 통밀"},
            { style : this.imageStyle.berry, kindsStr : "키위, 베리류(블루베리, 블랙베리, 아사이베리, 크렌베리, 라즈베리) , 레몬, 바나나,  딸기"},
            { style : this.imageStyle.egg, kindsStr : "계란, 메추리알, 오리알, 타조알, 캐비어,  조개류, 연어알, 날치알"},
            { style : this.imageStyle.vegetables1, kindsStr : "마늘,  연근,  당근,  양배추,  버섯,  브로콜리,  시금치,  무,  청경채,  오이,  배추,  양상추,  고추,  근대,  토란,  피망,  토란,  죽순"},
            { style : this.imageStyle.beans1, kindsStr : "두부, 청국장, 된장, 낫토"},
          ]
          this.recommendation.weak = [
            { style : this.imageStyle.corn, kindsStr : "보리,  옥수수,  수수,  조, 일반쌀"},
            { style : this.imageStyle.apple, kindsStr : "오렌지, 자몽, 석류, 적포도,  복숭아,  아보카도,  망고,  사과,  자두"},
            { style : this.imageStyle.almond, kindsStr : "아몬드, 해바라기씨, 잣, 호박씨, 마카다미아, 피칸"},
            { style : this.imageStyle.meatFish2Turbulence2, kindsStr : "닭,  칠면조,  오리,  등푸른 생선"},
            { style : this.imageStyle.onion, kindsStr : "양파,  가지,  파류,  호박,  생강,  고구마"},
          ]
          this.recommendation.passive = [
            { style : this.imageStyle.waterMelon, kindsStr : "푸룬(말린자두), 수박,  배,  감,  살구,  체리,  리치"},
            { style : this.imageStyle.sweetener400, kindsStr : "사카린, 아스팜탐, 아세설팜"},
            { style : this.imageStyle.pig, kindsStr : "돼지고기, 양고기, 명란젓"},
            { style : this.imageStyle.milkProducts1, kindsStr : "우유,  버터, 생크림, 연치즈, 생치즈"},
            { style : this.imageStyle.potato, kindsStr : "토마토, 감자"}
          ]
          this.recommendation.not = [
            { style : this.imageStyle.bread, kindsStr : " 국수류,  빵류"},
            { style : this.imageStyle.peanut, kindsStr : "땅콩, 호두"},
            { style : this.imageStyle.processedFood, kindsStr : "소시지, 햄, 베이컨"},
            { style : this.imageStyle.whippedCream, kindsStr : "아이스크림, 생크림, 연치즈, 생치즈"},
            { style : this.imageStyle.beans3, kindsStr : "완두콩,  대두,  병아리콩,  렌틸콩,  강낭콩"}
          ]
          break;
        case "FVBS":
          this.recommendation.best = [
            { style : this.imageStyle.corn, kindsStr : "현미,  옥수수,  수수,  조,  찹쌀"},
            { style : this.imageStyle.berry, kindsStr : "키위, 자몽, 석류, 베리류(블루베리, 블랙베리, 아사이베리, 크렌베리, 라즈베리) , 레몬"},
            { style : this.imageStyle.egg, kindsStr : "계란, 메추리알, 오리알, 타조알, 캐비어,  오리,  등푸른 생선, 연어알, 날치알"},
            { style : this.imageStyle.garlic, kindsStr : "마늘,  당근,  버섯,  가지,  무,  청경채,  오이,  배추,  근대,  토란,  피망,  토란,  죽순"},
            { style : this.imageStyle.beans1, kindsStr : "두부, 청국장, 된장, 낫토, 들기름"},
          ]
          this.recommendation.weak = [
            { style : this.imageStyle.grain, kindsStr : "귀리,  퀴노아 , 통밀"},
            { style : this.imageStyle.seaweed, kindsStr : "파래, 매생이 , 청각, 톳"},
            { style : this.imageStyle.almond, kindsStr : "아몬드, 해바라기씨, 잣, 호박씨, 마카다미아, 피칸"},
            { style : this.imageStyle.meatFish2Turbulence2, kindsStr : "닭,  칠면조,  조개류"},
            { style : this.imageStyle.vegetables1, kindsStr : "브로콜리,  시금치,  양배추,  양파,  파류,  호박,  생강,  양상추"},
          ]
          this.recommendation.passive = [
            { style : this.imageStyle.bread, kindsStr : "일반쌀,  빵류"},
            { style : this.imageStyle.waterMelon, kindsStr : "귤, 오렌지,  복숭아,  딸기,  아보카도, 수박,  사과,  배,  감"},
            { style : this.imageStyle.sweetener, kindsStr : "다시다, 굴소스, 미원, 맛소금, 기타 조미료, 올리고당(프락토올리고당, 대두올리고당, 갈락토올리당), 당알코올(자일리톨, 솔비톨)\""},
            { style : this.imageStyle.meatFish1Turbulence1, kindsStr : "쇠고기, 돼지고기, 양고기"},
            { style : this.imageStyle.potato, kindsStr : "토마토, 감자,  고구마"}
          ]
          this.recommendation.not = [
            { style : this.imageStyle.mango, kindsStr : "푸룬(말린자두),  망고,  자두,  살구,  체리,  리치"},
            { style : this.imageStyle.peanut, kindsStr : "땅콩, 호두"},
            { style : this.imageStyle.sweetener2, kindsStr : "당류(설탕, 물엿, 유당,포도당, 과당), 사카린, 아스팜탐, 아세설팜"},
            { style : this.imageStyle.processedFood, kindsStr : "소시지, 햄, 베이컨"},
            { style : this.imageStyle.whippedCream, kindsStr : "두유,  모짜렐라치즈, 아이스크림, 생크림, 연치즈, 생치즈, 생크림"}
          ]
          break;
        case "FVBW":
          this.recommendation.best = [
            { style : this.imageStyle.berry, kindsStr : "레몬, 베리류(블루베리, 블랙베리, 아사이베리, 크렌베리, 라즈베리) , 키위, 자몽"},
            { style : this.imageStyle.seaweed100, kindsStr : "미역, 톳, 다시마, 김, 우뭇가사리, 꼬시래기, 우뭇가사리, 꼬시래기"},
            { style : this.imageStyle.egg, kindsStr : "계란, 메추리알, 오리알, 타조알, 캐비어,  등푸른 생선,  조개류, 연어알, 날치알"},
            { style : this.imageStyle.vegetables1, kindsStr : "마늘,  당근,  브로콜리,  시금치,  양배추,  버섯,  양파,  가지,  파류,  호박,  생강,  무,  오이,  배추"},
            { style : this.imageStyle.beans1, kindsStr : "두부, 청국장, 된장, 낫토"},
          ]
          this.recommendation.weak = [
            { style : this.imageStyle.grain, kindsStr : "귀리,  옥수수,  조"},
            { style : this.imageStyle.banana, kindsStr : "귤, 오렌지, 석류, 바나나,  복숭아,  푸룬(말린자두),  자두,  체리"},
            { style : this.imageStyle.seaweed, kindsStr : "파래, 매생이 , 청각"},
            { style : this.imageStyle.almond, kindsStr : "아몬드, 해바라기씨, 잣, 호박씨, 마카다미아, 피칸"},
            { style : this.imageStyle.meatFish2Turbulence2, kindsStr : "돼지고기, 양고기, 닭,  칠면조,  오리"},
          ]
          this.recommendation.passive = [
            { style : this.imageStyle.rice, kindsStr : " 퀴노아 , 통밀, 일반쌀,  찹쌀"},
            { style : this.imageStyle.berry200, kindsStr : "적포도,  딸기,  사과,  배,  감,  살구,  리치"},
            { style : this.imageStyle.sweetener200, kindsStr : "올리고당(프락토올리고당,대두올리고당,갈락토올리당), 당알코올(자일리톨,솔비톨)\""},
            { style : this.imageStyle.milkProducts2, kindsStr : "두유,  우유,  모짜렐라치즈,  까망베르 치즈,  버터"},
            { style : this.imageStyle.beans3, kindsStr : "완두콩,  대두,  병아리콩,  렌틸콩,  강낭콩"}
          ]
          this.recommendation.not = [
            { style : this.imageStyle.bread, kindsStr : "보리,  국수류,  빵류"},
            { style : this.imageStyle.waterMelon, kindsStr : "아보카도, 수박,  망고"},
            { style : this.imageStyle.peanut, kindsStr : "땅콩, 호두"},
            { style : this.imageStyle.sweetener, kindsStr : "다시다, 굴소스, 미원, 맛소금, 기타 조미료, 당류(설탕, 물엿, 유당,포도당, 과당), 사카린, 아스팜탐, 아세설팜"},
            { style : this.imageStyle.processedFood, kindsStr : "소시지, 햄, 베이컨"},
          ]
          break;
        case "SMGS":
          this.recommendation.best = [
            { style : this.imageStyle.grain, kindsStr : "귀리,  보리,  현미,  퀴노아 , 통밀"},
            { style : this.imageStyle.berry, kindsStr : "키위, 자몽, 석류, 베리류(블루베리, 블랙베리, 아사이베리, 크렌베리, 라즈베리) , 레몬"},
            { style : this.imageStyle.seaweed, kindsStr : "파래, 매생이 , 청각, 미역, 톳, 다시마"},
            { style : this.imageStyle.egg, kindsStr : "계란, 메추리알, 오리알, 타조알, 캐비어,  등푸른 생선"},
            { style : this.imageStyle.carrot, kindsStr : "마늘,  연근,  토마토,  당근,  양배추,  버섯,  양파,  가지,  파류,  호박,  생강,  브로콜리,  시금치"},
          ]
          this.recommendation.weak = [
            { style : this.imageStyle.corn, kindsStr : " 옥수수,  수수,  조, 일반쌀,  찹쌀"},
            { style : this.imageStyle.berry200, kindsStr : "귤, 오렌지, 적포도, 바나나,  복숭아,  딸기,  푸룬(말린자두),  아보카도,  자두,  살구,  체리,  리치"},
            { style : this.imageStyle.almond, kindsStr : "땅콩, 아몬드, 호두, 해바라기씨, 잣, 호박씨, 마카다미아, 피칸, 캐슈너트, 브라질너트, 은행"},
            { style : this.imageStyle.seaweed1, kindsStr : "다시다, 굴소스, 미원, 맛소금, 기타 조미료, 당류(설탕, 물엿, 유당,포도당, 과당), 올리고당(프락토올리고당, 대두올리고당, 갈락토올리당), 당알코올(자일리톨, 솔비톨)"},
            { style : this.imageStyle.milkProducts2, kindsStr : "두유,  우유,  모짜렐라치즈,  까망베르 치즈,  버터"}
          ]
          this.recommendation.passive = [
            { style : this.imageStyle.bread, kindsStr : "국수류,  빵류"},
            { style : this.imageStyle.waterMelon, kindsStr : "수박,  망고,  사과,  배,  감"},
            { style : this.imageStyle.meatFish2Turbulence2, kindsStr : "닭,  칠면조,  오리, 명란젓"},
            { style : this.imageStyle.whippedCream, kindsStr : "아이스크림, 생크림, 연치즈, 생치즈"},
            { style : this.imageStyle.beans3, kindsStr : "완두콩,  대두,  병아리콩,  렌틸콩,  강낭콩"},
          ]
          this.recommendation.not = [
            { style : this.imageStyle.meat, kindsStr : "쇠고기, 돼지고기, 양고기"},
            { style : this.imageStyle.processedFood, kindsStr : "소시지, 햄, 베이컨"}
          ]
          break;
        case "SMGW":
          this.recommendation.best = [
            { style : this.imageStyle.grain, kindsStr : "귀리,  보리,  현미,  퀴노아 , 통밀,  옥수수,  수수,  조"},
            { style : this.imageStyle.fruit, kindsStr : "귤, 오렌지, 키위, 자몽, 석류, 베리류(블루베리, 블랙베리, 아사이베리, 크렌베리, 라즈베리) , 레몬"},
            { style : this.imageStyle.seaweed, kindsStr : "파래, 매생이 , 청각"},
            { style : this.imageStyle.carrot, kindsStr : "마늘,  연근,  토마토,  당근,  양배추,  버섯,  양파,  가지,  파류,  호박,  생강,  브로콜리,  시금치"},
            { style : this.imageStyle.beans1, kindsStr : "두부, 청국장, 된장, 낫토"},
          ]
          this.recommendation.weak = [
            { style : this.imageStyle.rice, kindsStr : "일반쌀,  빵류"},
            { style : this.imageStyle.banana, kindsStr : "적포도, 바나나,  복숭아,  딸기,  푸룬(말린자두),  아보카도,  망고,  사과,  배,  감,  자두,  살구,  체리,  리치"},
            { style : this.imageStyle.seaweed100, kindsStr : "미역, 톳, 다시마"},
            { style : this.imageStyle.almond, kindsStr : "땅콩, 아몬드, 호두, 해바라기씨, 잣, 호박씨, 마카다미아, 피칸"},
            { style : this.imageStyle.egg, kindsStr : "계란, 메추리알, 오리알, 타조알, 캐비어, 닭,  칠면조,  오리,  조개류"},
          ]
          this.recommendation.passive = [
            { style : this.imageStyle.noodle, kindsStr : "찹쌀,  국수류"},
            { style : this.imageStyle.seaweed200, kindsStr : "김, 우뭇가사리, 꼬시래기"},
            { style : this.imageStyle.sweetener, kindsStr : "다시다, 굴소스, 미원, 맛소금, 기타 조미료, 당류(설탕, 물엿, 유당,포도당, 과당), 사카린, 당알코올(자일리톨, 솔비톨), 아스팜탐, 아세설팜"},
            { style : this.imageStyle.milkProducts1, kindsStr : "모짜렐라치즈,  까망베르 치즈"},
            { style : this.imageStyle.beans3, kindsStr : "완두콩,  대두,  병아리콩,  렌틸콩,  강낭콩"},
          ]
          this.recommendation.not = [
            { style : this.imageStyle.pig, kindsStr : "쇠고기, 돼지고기, 양고기"},
            { style : this.imageStyle.processedFood, kindsStr : "소시지, 햄, 베이컨"},
            { style : this.imageStyle.butter, kindsStr : " 버터"},
            { style : this.imageStyle.whippedCream, kindsStr : "아이스크림, 생크림, 연치즈, 생치즈"}
          ]
          break;
        case "SMBS":
          this.recommendation.best = [
            { style : this.imageStyle.berry, kindsStr : "베리류(블루베리, 블랙베리, 아사이베리, 크렌베리, 라즈베리) ,  복숭아,  딸기,  푸룬(말린자두),  아보카도"},
            { style : this.imageStyle.egg, kindsStr : "계란, 메추리알, 오리알, 타조알, 캐비어,  등푸른 생선,  조개류, 연어알, 날치알"},
            { style : this.imageStyle.carrot, kindsStr : "마늘,  토마토,  당근,  양배추,  버섯,  양파,  가지,  파류,  호박,  생강,  브로콜리,  시금치,  무,  오이,  고구마"},
            { style : this.imageStyle.beans1, kindsStr : "두부, 청국장, 된장, 낫토"},
            { style : this.imageStyle.gangnamBeans, kindsStr : "강낭콩"},
          ]
          this.recommendation.weak = [
            { style : this.imageStyle.grain, kindsStr : "귀리,  현미,  퀴노아 , 통밀,  옥수수,  수수,  조, 일반쌀,  국수류,  빵류"},
            { style : this.imageStyle.waterMelon, kindsStr : "귤, 오렌지, 키위, 자몽, 레몬, 수박,  망고,  사과,  배"},
            { style : this.imageStyle.seaweed, kindsStr : "파래, 매생이 , 청각, 톳, 다시마, 김, 우뭇가사리, 꼬시래기, 우뭇가사리, 꼬시래기"},
            { style : this.imageStyle.almond, kindsStr : "아몬드, 해바라기씨, 호박씨, 마카다미아, 피칸, 캐슈너트, 브라질너트, 은행"},
            { style : this.imageStyle.potato, kindsStr : "감자,  청경채,  배추,  양상추,  고추,  근대,  토란,  피망,  토란,  죽순"},
          ]
          this.recommendation.passive = [
            { style : this.imageStyle.fruit3, kindsStr : "자두,  살구,  체리,  리치"},
            { style : this.imageStyle.sweetener, kindsStr : "다시다, 굴소스, 미원, 맛소금, 기타 조미료, 올리고당(프락토올리고당, 대두올리고당, 갈락토올리당)"},
            { style : this.imageStyle.meat, kindsStr : "쇠고기, 돼지고기, 양고기"},
            { style : this.imageStyle.whippedCream, kindsStr : "아이스크림, 생크림, 연치즈, 생치즈"},
            { style : this.imageStyle.beans3, kindsStr : "대두,  병아리콩,  렌틸콩"},
          ]
          this.recommendation.not = [
            { style : this.imageStyle.banana, kindsStr : "석류, 적포도, 바나나,  감"},
            { style : this.imageStyle.peanut, kindsStr : "땅콩, 호두"},
            { style : this.imageStyle.sweetener200, kindsStr : "당류(설탕, 물엿, 유당,포도당, 과당), 사카린, 당알코올(자일리톨, 솔비톨), 아스팜탐, 아세설팜"},
            { style : this.imageStyle.processedFood, kindsStr : "소시지, 햄, 베이컨"},
            { style : this.imageStyle.milkProducts2, kindsStr : "우유"},
          ]
          break;
        case "SMBW":
          this.recommendation.best = [
            { style : this.imageStyle.berry, kindsStr : "베리류(블루베리, 블랙베리, 아사이베리, 크렌베리, 라즈베리) , 귤, 오렌지, 키위, 자몽, 석류"},
            { style : this.imageStyle.seaweed, kindsStr : "파래, 매생이 , 청각"},
            { style : this.imageStyle.egg, kindsStr : "계란, 메추리알, 오리알, 타조알, 캐비어,  오리,  등푸른 생선,  조개류, 연어알, 날치알"},
            { style : this.imageStyle.carrot, kindsStr : "당근,  버섯,  양파,  가지,  호박,  생강,  브로콜리,  시금치,  무,  오이,  배추,  근대,  토란,  피망"},
            { style : this.imageStyle.beans1, kindsStr : "두부, 청국장, 된장, 낫토"},
          ]
          this.recommendation.weak = [
            { style : this.imageStyle.grain, kindsStr : "귀리,  현미,  퀴노아 , 통밀,  옥수수,  수수,  조"},
            { style : this.imageStyle.apple, kindsStr : "레몬, 적포도,  사과,  자두,  살구,  체리,  리치"},
            { style : this.imageStyle.seaweed100, kindsStr : "미역, 톳, 다시마, 김, 우뭇가사리, 꼬시래기, 우뭇가사리, 꼬시래기"},
            { style : this.imageStyle.meatFish2Turbulence2, kindsStr : "닭,  칠면조"},
            { style : this.imageStyle.potato, kindsStr : "마늘,  토마토,  파류, 감자,  청경채,  양상추,  토란,  죽순,  고구마"},
          ]
          this.recommendation.passive = [
            { style : this.imageStyle.rice, kindsStr : "일반쌀,  찹쌀"},
            { style : this.imageStyle.banana, kindsStr : "바나나,  복숭아,  딸기,  푸룬(말린자두),  아보카도, 수박,  망고,  배,  감"},
            { style : this.imageStyle.almond, kindsStr : "아몬드, 해바라기씨, 잣, 호박씨, 마카다미아, 피칸"},
            { style : this.imageStyle.sweetener, kindsStr : "다시다, 굴소스, 미원, 맛소금, 기타 조미료, 올리고당(프락토올리고당, 대두올리고당, 갈락토올리당)"},
            { style : this.imageStyle.meat, kindsStr : "쇠고기, 돼지고기, 양고기"},
          ]
          this.recommendation.not = [
            { style : this.imageStyle.noodle, kindsStr : " 보리,  국수류,  빵류"},
            { style : this.imageStyle.sweetener200, kindsStr : "당류(설탕, 물엿, 유당,포도당, 과당), 사카린, 당알코올(자일리톨, 솔비톨), 아스팜탐, 아세설팜"},
            { style : this.imageStyle.processedFood, kindsStr : "소시지, 햄, 베이컨"},
            { style : this.imageStyle.milkProducts2, kindsStr : "우유, 아이스크림, 생크림, 연치즈, 생치즈, 생크림, 연치즈, 생치즈"},
            { style : this.imageStyle.beans3, kindsStr : "완두콩,  대두,  병아리콩,  렌틸콩,  강낭콩"},
          ]
          break;
        case "SVGS":
          this.recommendation.best = [
            { style : this.imageStyle.grain, kindsStr : "귀리,  보리,  현미,  퀴노아 "},
            { style : this.imageStyle.berry, kindsStr : "귤, 오렌지, 키위, 자몽, 석류, 베리류(블루베리, 블랙베리, 아사이베리, 크렌베리, 라즈베리) , 레몬, 적포도"},
            { style : this.imageStyle.seaweed, kindsStr : "파래, 매생이 , 청각"},
            { style : this.imageStyle.peanut, kindsStr : "땅콩, 아몬드, 호두, 해바라기씨, 잣, 호박씨, 마카다미아, 피칸"},
            { style : this.imageStyle.meat, kindsStr : "쇠고기, 돼지고기, 양고기, 계란, 메추리알, 오리알, 타조알, 캐비어"},
          ]
          this.recommendation.weak = [
            { style : this.imageStyle.corn, kindsStr : "통밀,  옥수수,  수수,  조, 일반쌀,  찹쌀,  국수류,  빵류"},
            { style : this.imageStyle.banana, kindsStr : "바나나,  복숭아,  딸기,  푸룬(말린자두),  아보카도,  자두,  살구,  체리,  리치"},
            { style : this.imageStyle.seaweed100, kindsStr : "미역, 톳, 다시마"},
            { style : this.imageStyle.meatFish2Turbulence2, kindsStr : "닭,  칠면조,  오리,  등푸른 생선,  조개류, 연어알, 날치알"},
            { style : this.imageStyle.beans2, kindsStr : "들기름,  참기름,  올리브오일,  코코넛오일,  해조유, 아보카도오일"},
          ]
          this.recommendation.passive = [
            { style : this.imageStyle.waterMelon, kindsStr : "수박, 망고, 사과, 배, 감"},
            { style : this.imageStyle.seaweed200, kindsStr : "김, 우뭇가사리, 꼬시래기"},
            { style : this.imageStyle.whippedCream, kindsStr : "아이스크림, 생크림, 연치즈, 생치즈"},
            { style : this.imageStyle.potato, kindsStr : "감자, 무, 청경채, 오이, 배추, 양상추, 고추, 근대, 토란, 피망, 토란, 죽순, 고구마, 덜익은 과일, 채소"}
          ]
          this.recommendation.not = [
            { style : this.imageStyle.sweetener200, kindsStr : "사카린, 아스팜탐, 아세설팜"},
            { style : this.imageStyle.processedFood, kindsStr : "소시지, 햄, 베이컨"}
          ]
          break;
        case "SVGW":
          this.recommendation.best = [
            { style : this.imageStyle.grain, kindsStr : "귀리, 현미"},
            { style : this.imageStyle.berry, kindsStr : "귤, 오렌지, 키위, 자몽, 석류, 베리류(블루베리, 블랙베리, 아사이베리, 크렌베리, 라즈베리) , 레몬"},
            { style : this.imageStyle.meat, kindsStr : "쇠고기, 돼지고기, 양고기"},
            { style : this.imageStyle.carrot, kindsStr : "마늘, 연근, 토마토, 당근, 양배추, 버섯, 양파, 가지, 파류, 호박, 생강, 브로콜리, 시금치"},
            { style : this.imageStyle.beans1, kindsStr : "두부, 청국장, 된장, 낫토"},
          ]
          this.recommendation.weak = [
            { style : this.imageStyle.corn, kindsStr : "보리, 퀴노아, 통밀, 옥수수, 수수, 조"},
            { style : this.imageStyle.banana, kindsStr : "적포도, 바나나, 복숭아, 딸기, 푸룬(말린자두), 아보카도, 자두, 살구, 체리, 리치"},
            { style : this.imageStyle.seaweed, kindsStr : "파래, 매생이 , 청각"},
            { style : this.imageStyle.peanut, kindsStr : "땅콩, 아몬드, 해바라기씨, 잣, 호박씨, 마카다미아, 피칸"},
            { style : this.imageStyle.egg, kindsStr : "계란, 메추리알, 오리알, 타조알, 캐비어, 닭,  칠면조,  오리,  등푸른 생선,  조개류, 연어알, 날치알"},
          ]
          this.recommendation.passive = [
            { style : this.imageStyle.waterMelon, kindsStr : "수박, 망고, 사과, 배, 감"},
            { style : this.imageStyle.sweetener, kindsStr : "다시다, 굴소스, 미원, 맛소금, 기타 조미료, 당류(설탕, 물엿, 유당,포도당, 과당), 올리고당(프락토올리고당, 대두올리고당, 갈락토올리당), 당알코올(자일리톨, 솔비톨)"},
            { style : this.imageStyle.milkProducts1, kindsStr : "모짜렐라치즈, 까망베르 치즈"},
            { style : this.imageStyle.vegetables2, kindsStr : "감자,  무,  청경채,  오이,  배추,  양상추,  고추,  근대,  토란,  피망,  토란,  죽순,  고구마, 덜익은 과일, 채소"}
          ]
          this.recommendation.not = [
            { style : this.imageStyle.noodle, kindsStr : "일반쌀, 찹쌀, 국수류, 빵류"},
            { style : this.imageStyle.sweetener200, kindsStr : "사카린, 아스팜탐, 아세설팜"},
            { style : this.imageStyle.processedFood, kindsStr : "소시지, 햄, 베이컨"},
            { style : this.imageStyle.butter, kindsStr : "버터"},
            { style : this.imageStyle.whippedCream, kindsStr : "아이스크림, 생크림, 연치즈, 생치즈"},
          ]
          break;
        case "SVBS":
          this.recommendation.best = [
            { style : this.imageStyle.berry, kindsStr : "귤, 오렌지, 베리류(블루베리, 블랙베리, 아사이베리, 크렌베리, 라즈베리) , 레몬"},
            { style : this.imageStyle.seaweed, kindsStr : "파래, 매생이 , 청각"},
            { style : this.imageStyle.egg, kindsStr : "계란, 메추리알, 오리알, 타조알, 캐비어"},
            { style : this.imageStyle.garlic, kindsStr : "마늘,  당근,  양배추,  버섯,  양파,  가지,  파류,  호박,  생강,  브로콜리,  시금치"},
            { style : this.imageStyle.beans1, kindsStr : "두부, 청국장, 된장, 낫토"},
          ]
          this.recommendation.weak = [
            { style : this.imageStyle.grain, kindsStr : "귀리, 옥수수, 수수, 조, 일반쌀, 국수류"},
            { style : this.imageStyle.waterMelon, kindsStr : "수박, 망고, 사과, 배, 감, 자두, 살구, 체리, 리치"},
            { style : this.imageStyle.seaweed100, kindsStr : "미역, 톳, 다시마"},
            { style : this.imageStyle.meat, kindsStr : "쇠고기, 돼지고기, 양고기"},
            { style : this.imageStyle.potato, kindsStr : "감자, 무, 청경채, 오이, 배추, 양상추, 고추, 근대, 토란, 피망, 토란, 죽순, 고구마"}
          ]
          this.recommendation.passive = [
            { style : this.imageStyle.bread, kindsStr : "현미, 퀴노아, 통밀, 빵류"},
            { style : this.imageStyle.banana, kindsStr : "키위, 자몽, 석류, 적포도, 바나나,  복숭아,  딸기,  푸룬(말린자두),  아보카도"},
            { style : this.imageStyle.almond, kindsStr : "아몬드, 해바라기씨, 잣, 호박씨, 마카다미아, 피칸"},
            { style : this.imageStyle.beans2, kindsStr : "들기름, 참기름, 올리브오일, 코코넛오일, 해조유, 아보카도오일"},
            { style : this.imageStyle.beans3, kindsStr : "완두콩, 대두, 병아리콩, 렌틸콩, 강낭콩"},
          ]
          this.recommendation.not = [
            { style : this.imageStyle.barley, kindsStr : "보리"},
            { style : this.imageStyle.peanut, kindsStr : "땅콩, 호두"},
            { style : this.imageStyle.sweetener200, kindsStr : "당류(설탕, 물엿, 유당,포도당, 과당), 당알코올(자일리톨, 솔비톨), 아스팜탐, 아세설팜"},
            { style : this.imageStyle.processedFood, kindsStr : "소시지, 햄, 베이컨"},
            { style : this.imageStyle.milkProducts2, kindsStr : " 우유"},
          ]
          break;
        case "SVBW":
          this.recommendation.best = [
            { style : this.imageStyle.berry, kindsStr : "귤, 오렌지, 베리류(블루베리, 블랙베리, 아사이베리, 크렌베리, 라즈베리) , 레몬"},
            { style : this.imageStyle.seaweed, kindsStr : "파래, 매생이 , 청각, 김, 우뭇가사리, 꼬시래기"},
            { style : this.imageStyle.egg, kindsStr : "계란, 메추리알, 오리알, 타조알, 캐비어"},
            { style : this.imageStyle.meatFish2Turbulence2, kindsStr : "닭,  칠면조,  오리,  등푸른 생선"},
            { style : this.imageStyle.garlic, kindsStr : "마늘, 당근, 양배추, 버섯, 양파, 가지, 파류, 호박, 생강, 브로콜리, 시금치"}
          ]
          this.recommendation.weak = [
            { style : this.imageStyle.corn, kindsStr : "귀리, 옥수수, 수수, 조, 일반쌀, 찹쌀, 국수류"},
            { style : this.imageStyle.banana, kindsStr : "키위, 자몽, 석류, 적포도, 바나나, 복숭아, 딸기, 푸룬(말린자두), 아보카도, 수박, 망고, 사과, 배, 감, 자두, 살구, 체리, 리치"},
            { style : this.imageStyle.seaweed100, kindsStr : "미역, 톳, 다시마"},
            { style : this.imageStyle.meat, kindsStr : "쇠고기, 돼지고기, 양고기"},
            { style : this.imageStyle.potato, kindsStr : "감자, 무, 청경채, 오이, 배추, 양상추, 고추, 근대, 토란, 피망, 토란, 죽순, 고구마"}
          ]
          this.recommendation.passive = [
            { style : this.imageStyle.almond, kindsStr : "아몬드, 해바라기씨, 잣, 호박씨, 마카다미아, 피칸"},
            { style : this.imageStyle.sweetener, kindsStr : "다시다, 굴소스, 미원, 맛소금, 기타 조미료, 올리고당(프락토올리고당, 대두올리고당, 갈락토올리당), 사카린"},
            { style : this.imageStyle.scenicCheese, kindsStr : "경치즈, 체다치즈, 유당이 없는 우유(락토프리우유), 유당이 없는 요쿠르트(락토프리요쿠르트)"},
            { style : this.imageStyle.tomato, kindsStr : "토마토"},
            { style : this.imageStyle.beans2, kindsStr : "해조유, 아보카도오일"},
          ]
          this.recommendation.not = [
            { style : this.imageStyle.peanut, kindsStr : "땅콩, 호두"},
            { style : this.imageStyle.sweetener200, kindsStr : "당류(설탕, 물엿, 유당,포도당, 과당), 당알코올(자일리톨, 솔비톨), 아스팜탐, 아세설팜"},
            { style : this.imageStyle.processedFood, kindsStr : "소시지, 햄, 베이컨"},
            { style : this.imageStyle.milkProducts2, kindsStr : "우유"},
            { style : this.imageStyle.whippedCream, kindsStr : "아이스크림, 생크림, 연치즈, 생치즈"},
          ]
          break;
        default :
          this.recommendation = {
            best: "",
            weak: "",
            passive: "",
            not: ""
          }



      }
    },

    async saveComment() {

      let data = {
        code : this.sample.code,
        comment : JSON.stringify(this.comment)
      }

      try {
        let resp = await axios.post(`/v2/report/comment`, data);
        if (resp.data.code !== 200) {
          return "데이터 처리 실패 code:" + resp.data.code;
        }
      } catch (e) {
        console.log(e);
        return "잔소리 쓴소리 데이터 저장에 실패 하였습니다.";
      }
      return "잔소리 쓴소리 데이터 저장 완료";
    },

  },
  mounted() {
    //this.changeTypeContents("SMBS", "mount 로직 제거")

  }
}
</script>

<style lang="scss" scoped>
p::v-deep p {
  padding: 0;
  margin-bottom: 0px !important;
  font-size: 18px;
}
.kind_str {
  height: 67px !important;
  display: -webkit-box !important;
  overflow: hidden;
  word-break: break-all;
  word-wrap: break-word;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  //word-break: keep-all
}
@import '../../theme/reportV2.scss';
</style>